import React, { useMemo, useState } from 'react';
import './ApprovalSection.scss';
import { t } from './../../../libraries/i18n';
import { Submission, SubmissionStatus, SubmissionCategory, SubmissionType } from './../../../interfaces/approvals';
import SubmissionItem from './../SubmissionItem/SubmissionItem';
import SubmissionImage from './../SubmissionImage/SubmissionImage';
import { useParams } from 'react-router-dom';

interface Props {
	submissions: any
	category: SubmissionCategory
}

const REASONS = {
	[SubmissionCategory.TEXT]: [
		{
			id: '1'
		},
		{
			id: '2'
		},
		{
			id: '3'
		},
		{
			id: '4'
		},
		{
			id: '5'
		},
		{
			id: '6'
		},
		{
			id: '7'
		}
	],
	[SubmissionCategory.IMAGE]: [
		{
			id: '1'
		},
		{
			id: '2'
		},
		{
			id: '3'
		},
		{
			id: '4'
		},
		{
			id: '5'
		},
		{
			id: '6'
		},
		{
			id: '7'
		},
		{
			id: '8'
		},
		{
			id: '9'
		},
		{
			id: '10'
		},
		{
			id: '11'
		},
		{
			id: '12'
		},
		{
			id: '13'
		},
		{
			id: '14'
		},
		{
			id: '15'
		},
		{
			id: '16'
		},
		{
			id: '17'
		},
		{
			id: '18'
		},
		{
			id: '19'
		}
	]
};

const CATEGORY_TYPES = {
	[SubmissionCategory.TEXT]: [
		SubmissionType.NL,
		SubmissionType.FR,
		SubmissionType.DE,
		SubmissionType.EN
	],
	[SubmissionCategory.IMAGE]: [
		SubmissionType.PACKSHOT,
		SubmissionType.MOODSHOT
	]
};
interface TextChanges {
	[index: string]: string
}

export default function ApprovalSection(props: Props) {
	const originalText = useMemo<TextChanges>(() => {
		const texts: TextChanges = { fr: "", nl: "", de: "", en: "" };
		props.submissions.forEach((submission: Submission) => {
			texts[submission.type] = submission.value;
		});
		return texts
	}, [props.submissions]);

	const [textChangesbyInternal, setTextChangesbyInternal] = useState<TextChanges>(() => {
		const texts: TextChanges = {};
		props.submissions.forEach((submission: Submission) => {
			texts[submission.id] = submission.value;
		});
		return texts
	});

	const { id } = useParams<{ id: string }>();

	// check if submissions has type/category
	const hasItems = (items: Submission[], value: SubmissionType | SubmissionCategory, type: 'type'|'category' = 'category') => {
		value = type === 'category' ? value as SubmissionCategory : value as SubmissionType;
		return items.some(item => item[type] === value && item.status ===  SubmissionStatus.SUBMITTED)
	}

	const getActiveSubmission = (submissions: Submission[], type: SubmissionType) => {
		return submissions
			.filter(s => s.type === type && s.status === SubmissionStatus.APPROVED)
			.sort((a, b) => a.since.getTime() - b.since.getTime())
			.slice(-1)?.[0];
	}

	const getSubmittedSubmissions = (submissions: Submission[], type: SubmissionType) => {
		return submissions.filter(s => s.type === type && s.status === SubmissionStatus.SUBMITTED);
	}

	const renderSubmissionValue = (s: Submission) => {
		if (s.category === SubmissionCategory.IMAGE) return (
			<SubmissionImage
				submission={s}
				product={id}
				editable={s.status === 10}
			/>
		);

		if (!s.active) return (
			<textarea
				className={`submission ${s.category}`}
				onChange={(e) => setTextChangesbyInternal(p => ({ ...p, [s.id]: e.target.value }))}
				value={s.active ? originalText[s.type] : textChangesbyInternal[s.id]}>
			</textarea>
		)
		
		return (
			<div className={`submission ${s.category}`}>
				{s.value}
			</div>
		)
	};

	if (!hasItems(props.submissions, props.category)) return null

	return (
		<div className='ApprovalSection'>
			<h2>{t(`approvals.title_category_${props.category}`)}</h2>

			{CATEGORY_TYPES[props.category].map(type => {
				const active = getActiveSubmission(props.submissions, type);
				const submitted = getSubmittedSubmissions(props.submissions, type);

				if (!hasItems(props.submissions, type, 'type')) return null;

				return (
					<div key={type} className='subsection'>
						<h3>{t(`approvals.title_type_${type}`)}</h3>
						{active && <SubmissionItem
							submission={{...active, active: true}}
							renderSubmission={renderSubmissionValue}
							REASONS={REASONS[props.category]}
							textChangesbyInternal={textChangesbyInternal[active.id]}
						/>}

						{!active && (
							<div className='submission no-active'>
								{t('approvals.lables_no_active_submission')}
							</div>
						)}

						{submitted?.map(submission => (
							<SubmissionItem
								key={submission.id}
								submission={submission}
								renderSubmission={renderSubmissionValue}
								REASONS={REASONS[props.category]}
								textChangesbyInternal={textChangesbyInternal[submission.id]}
							/>
						))}
					</div>
				)
			})}
		</div>
	);
}
