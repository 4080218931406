import { Link, useParams } from "react-router-dom"
import { FormEvent, MouseEvent, useEffect, useMemo, useState } from "react";
import { TradeFair, TradeFairRegistration } from "../index.types";
import { get } from "../../../libraries/api";
import { List, Spinner } from '@abm-international/react-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSync, faTimesCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import './TradeFairRegistrations.scss';
import axios from "axios";
import { DEFAULT_AXIOS_CONFIG } from "../../../api/index";
import { TradeFairAttendanceBlock, calculateAttendees } from "../TradeFairAttendanceBlock/TradeFairAttendanceBlock";
import { Totals } from "./Totals";

const URL = '/api/v1/trade-fairs';
const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;

function reformatDateTimeHelper(dateTimeString: string) {
    if (!regex.test(dateTimeString)) return dateTimeString;
    const [datePart, timePart] = dateTimeString.split(' ');
    const [year, month, day] = datePart.split('-');
    const formattedDatePart = `${day}-${month}-${year}`;
    const formattedDateTimeString = `${formattedDatePart} ${timePart}`;
    return formattedDateTimeString;
}


export const formatDate = (date?: string, endDate?: string, timestamped?: boolean) => {
    if (!date) return '';

    let date_d = new Date(date);

    if (timestamped) {
        const offset = date_d.getTimezoneOffset();
        date_d = new Date(date_d.getTime() - offset * 60 * 1000);
    }

    const _date = date_d.toISOString().replace('T', ' ').substring(0, 16);

    if (endDate) {
        const _endDate = (endDate || '').replace('T', ' ').substring(0, 16).replace(_date.substring(0, 11), '');

        return `${reformatDateTimeHelper(_date)} - ${reformatDateTimeHelper(_endDate)}`;
    }

    return reformatDateTimeHelper(_date);
}

const getUniqueDays = (dateRanges: string[]): string[] => {
    const uniqueDays = new Set<string>();
    dateRanges.forEach(range => {
        const [start] = range.split('/');
        const [date] = start.split(' ');
        uniqueDays.add(date);
    });
    return Array.from(uniqueDays);
}

const basicSort = (col: string) => {
    return (a: any, b: any) => {
        const keys = col.split('.');
        let itemValueA = a;
        let itemValueB = b;

        keys.forEach(key => {
            itemValueA = itemValueA?.[key];
            itemValueB = itemValueB?.[key];
        });
    };
};

const defaultNewRegistration: { [index: string]: number | '' } = {
    attendees: 0,
    attendeesExtra: 0,
    attendeesExtra2: 0,
};

export interface TradeFairRegistrationCount {
    date?: string | Date,
    attendees: number,
    surPlusAttendees?: number,
    attendeesExtra: number,
    surPlusAttendeesExtra?: number,
    clients: number,
}

function sumAttendees(tradeFairRegistration: TradeFairRegistration, initial?: TradeFairRegistrationCount, date?: string): TradeFairRegistrationCount {
    if (!tradeFairRegistration.registrations?.length) {
        return initial || {
            attendees: 0,
            surPlusAttendees: 0,
            attendeesExtra: 0,
            surPlusAttendeesExtra: 0,
            clients: 0,
        };
    }

    const totalAttendees = tradeFairRegistration.registrations.reduce((sum, registration) => sum + (!date || registration.timestamp.indexOf(date) === 0 ? registration.attendees : 0), 0);
    const maxAttendees = tradeFairRegistration.attendees;
    const retAttendees = totalAttendees > maxAttendees ? maxAttendees : totalAttendees;
    const retSurPlusAttendees = totalAttendees > maxAttendees ? totalAttendees - maxAttendees : 0;

    const totalAttendeesExtra = tradeFairRegistration.registrations.reduce((sum, registration) => sum + (registration?.attendeesExtra && (!date || registration.timestamp.indexOf(date) === 0) ? registration?.attendeesExtra : 0), 0);
    const maxAttendeesExtra = tradeFairRegistration.attendeesExtra;
    const retAttendeesExtra = totalAttendeesExtra > maxAttendeesExtra ? maxAttendeesExtra : totalAttendeesExtra;
    const retSurPlusAttendeesExtra = totalAttendeesExtra > maxAttendeesExtra ? totalAttendeesExtra - maxAttendeesExtra : 0;
    const retClients = retAttendees ? 1 : 0

    return {
        attendees: (initial?.attendees || 0) + retAttendees,
        surPlusAttendees: (initial?.surPlusAttendees || 0) + retSurPlusAttendees,
        attendeesExtra: (initial?.attendeesExtra || 0) + retAttendeesExtra,
        surPlusAttendeesExtra: (initial?.surPlusAttendeesExtra || 0) + retSurPlusAttendeesExtra,
        clients: (initial?.clients || 0) + retClients
    };
}

const test = (registrations?: TradeFairRegistration[], date?: string) => {
    return (registrations || []).reduce<TradeFairRegistrationCount>((sum, registration) => sumAttendees(registration, sum, date), {
        attendees: 0,
        surPlusAttendees: 0,
        attendeesExtra: 0,
        surPlusAttendeesExtra: 0,
        clients: 0,
    });
}

export const TradeFairRegistrations = () => {
    const { tradeFairId } = useParams<{ tradeFairId: string }>();

    const [page, setPage] = useState(0);
    const [filter, setFilter] = useState('');
    const [tradeFair, setTradeFair] = useState<TradeFair>();
    const [registrations, setRegistrations] = useState<(TradeFairRegistration & { klt_pst?: number, klt_gem?: string })[]>();

    const [fetchingRegistrations, setFetchingRegistrations] = useState(false);
    const [activeRegistration, setActiveRegistration] = useState<TradeFairRegistration>();

    const [newRegistration, setNewRegistration] = useState(defaultNewRegistration);
    const [registering, setRegistering] = useState(false);
    const [registeringError, setRegisteringError] = useState('');

    const [forceFilter, setForceFilter] = useState(Date.now());

    const filteredRegistrations = useMemo(() => {
        if (!filter) return registrations;

        const _filter = filter.toLowerCase().trim();

        let _registrations = registrations?.filter(registration => (registration.customerName || '')?.toLowerCase().indexOf(_filter) > -1 || String(registration.customerId).toLowerCase().indexOf(_filter) > -1)

        if (_filter && !isNaN(Number(_filter))) {
            _registrations = _registrations?.sort((a, b) => a.customerId > b.customerId ? 1 : -1);
        }
        setPage(0);
        return _registrations;
    }, [registrations, filter, forceFilter])

    const registrationTotalsOnDays = useMemo(() => {
        return getUniqueDays(tradeFair?.dates || []).map(day => {
            return {
                date: day,
                totals: registrations?.length ? test(registrations, day) : { attendees: 0, surPlusAttendees: 0, attendeesExtra: 0, surPlusAttendeesExtra: 0, clients: 0 },
                totalsExpected: (registrations || []).reduce<TradeFairRegistrationCount>((sum, registration) => ({
                    attendees: sum.attendees + (registration.attendanceDateStart.indexOf(day) === 0 ? registration.attendees : 0),
                    attendeesExtra: sum.attendeesExtra + (registration.attendanceDateStart.indexOf(day) === 0 ? registration.attendeesExtra : 0),
                    clients: sum.clients + (registration.attendanceDateStart.indexOf(day) === 0 ? 1 : 0),
                }), {
                    attendees: 0,
                    attendeesExtra: 0,
                    clients: 0
                }),
            }
        });
    }, [registrations, tradeFair?.dates, forceFilter]);

    const [trigger, setTrigger] = useState(Date.now());

    const getProspects = async () => {
        const response = await get(`/api/v1/sync/tradefair/additional`);
        setTrigger(Date.now())
    }

    const updateRegistrations = (oldReg?: any[], newReg?: any[]) => {
        if (!oldReg?.length) {
            return newReg?.length ? newReg : [];
        };

        newReg?.forEach(reg => {
            const index = oldReg?.findIndex(old => old.id === reg.id);

            if (index >= 0) {
                oldReg[index] = reg;
            } else {
                oldReg.push(reg);
            }
        });

        return oldReg;
    }

    useEffect(() => {
        setTradeFair(undefined);
        // setRegistrations([]);
        setFetchingRegistrations(true);

        (async () => {
            const response = await get(`${URL}/${tradeFairId}`);
            if (response.success) {
                setTradeFair(response.data);
            }
        })();

        (async () => {
            // TODO: Implement caching if soft-delete is enabled
            // const suffix = registrations?.length && trigger ? '?from=' + (trigger + 1000 * 60 * 60 * 1) : ''; // add timzeone (2 hours) to trigger to fix timeshift with db
            // const response = await get(`${URL}/${tradeFairId}/registrations${suffix}`);

            const response = await get(`${URL}/${tradeFairId}/registrations`);

            if (response.success) {
                // TODO: Implement caching if soft-delete is enabled
                // setRegistrations((prev) => updateRegistrations(prev, response.data));
                // setForceFilter(Date.now());

                setRegistrations(response.data);
            } else {
                setRegistrations([]);
            }
            setFetchingRegistrations(false);
        })();
    }, [tradeFairId, trigger]);

    useEffect(() => {
        setNewRegistration(defaultNewRegistration);
    }, [activeRegistration])

    const resetNewRegistration = () => {
        setNewRegistration(defaultNewRegistration);
        setRegisteringError('');
    };

    const updateActiveRegistration = (e?: MouseEvent<HTMLDivElement>, registration?: TradeFairRegistration) => {
        if (String(e?.target) === '[object HTMLDivElement]' || String(e?.target) === '[object HTMLBodyElement]') {
            e?.stopPropagation();
        }

        setActiveRegistration(prev => {
            return prev?.id === registration?.id ? undefined : registration;
        });
    };

    const confirmRegistration = async (e: FormEvent) => {
        e.preventDefault();

        if (!activeRegistration?.customerId || (!newRegistration.attendees && !newRegistration.attendeesExtra)) return;

        setRegistering(true);

        const registration = {
            timestamp: new Date(),
            attendees: newRegistration.attendees,
            attendeesExtra: newRegistration.attendeesExtra,
            attendeesExtra2: newRegistration.attendeesExtra2,
        }

        try {
            const response = await axios.post(`v1/trade-fairs/${tradeFairId}/registration/${activeRegistration.customerId}/new`, registration, DEFAULT_AXIOS_CONFIG);
            if (response.data.success) {
                resetNewRegistration();
                setRegistrations(prev => {
                    const newRegs = [...(prev || [])];
                    const index = newRegs?.findIndex(reg => reg.id === response.data.data.id);
                    if (index > -1) {
                        newRegs[index] = { ...newRegs[index], ...response.data.data };
                    }
                    return newRegs;
                })
                if (activeRegistration?.id === response.data.data.id) {
                    setActiveRegistration(prev => ({ ...prev, ...response.data.data }));
                }

                setTrigger(Date.now());
            } else {
                setRegisteringError('There was no success registering the attendance');
            }
            setRegistering(false);
        } catch (err: any) {
            console.error(err);
            setRegisteringError(`${err.body?.error_code || '0'}:  ${err.body?.error_message || 'OEPS'}`);
            // TODO: show notification or something...
            setRegistering(false);
        }
    }

    const removeRegistrationAttendance = async (registration: TradeFairRegistration, index: number) => {
        const answer = window.confirm('Opgelet!\nBent u zeker dat je deze registratie wilt verwijderen?\n(deze actie kan niet ongedaan worden)');
        if (!answer) return;

        try {
            const regToDelete = registration.registrations?.[index];
            const response = await axios.post(`v1/trade-fairs/${tradeFairId}/registration/${registration.customerId}/deleteRegistrationAttendance`, regToDelete, DEFAULT_AXIOS_CONFIG);

            if (response.data.success) {
                setTrigger(Date.now());

                if (activeRegistration?.id === response.data.data.id) {
                    setActiveRegistration(prev => ({ ...prev, ...response.data.data }));
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="TradeFairRegistrations">
            <div className="tfr-header">
                <Link to='/trade-fairs/' className="return-link">
                    <FontAwesomeIcon icon={faArrowLeft} />
                </Link>

                <h1>Evenementen &gt; {tradeFairId}</h1>

                <div className="refresh-data">
                    Refresh
                    <button
                        className='refresh-btn'
                        onClick={async () => {
                            getProspects();
                        }}
                    ><FontAwesomeIcon icon={faSync} /></button>
                </div>
            </div>

            <div className="totals_container">
                {registrationTotalsOnDays.map((day, index) => (
                    <Totals key={'totals-' + index} totals={day.totals} totalsExpected={day.totalsExpected} day={day.date} />
                ))}
            </div>

            <div className="tfr-filter">
                <input
                    type="search"
                    id="filter"
                    name="filter"
                    placeholder="Zoek op unieke code of naam"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                />
            </div>

            <div className='visits-wrapper'>
                <List
                    className='visits-list'
                    action={updateActiveRegistration}
                    header={[
                        {
                            key: 0,
                            title: 'Klant',
                            render: ({ customerId, customerName }: { customerId: number, customerName: string }) => <>{customerName} ({customerId})</>,
                            // sort: basicSort('customerId'),
                        },
                        {
                            key: 1,
                            title: 'Adres',
                            render: ({ klt_pst, klt_gem }: { klt_pst: number, klt_gem: string }) => <>{klt_gem}{klt_pst ? ` (${klt_pst})` : ''}</>,
                            // sort: basicSort('customerId'),
                        },
                        {
                            key: 2,
                            title: 'Datum & Tijd',
                            render: ({ attendanceDateStart, attendanceDateEnd }: { attendanceDateStart: string, attendanceDateEnd: string }) => formatDate(attendanceDateStart, attendanceDateEnd),
                            // sort: basicSort('attendanceDateStart'),
                        },
                        {
                            key: 3,
                            title: 'Volwassenen',
                            render: ({ attendees, registrations }: { attendees: number, registrations: any[] }) => <>{calculateAttendees('attendees', registrations)} / {attendees}</>,
                            // sort: basicSort('attendees'),
                        },
                        ...(tradeFair?.attendeesExtra ? [{
                            key: 4,
                            title: 'Kinderen',
                            render: ({ attendeesExtra, registrations }: { attendeesExtra: number, registrations: any[] }) => <>{calculateAttendees('attendeesExtra', registrations)} / {attendeesExtra}</>,
                            // sort: basicSort('attendeesExtra'),
                        }] : []),
                        ...(tradeFair?.attendeesExtra2 ? [{
                            key: 5,
                            title: 'Attendees Extra 2',
                            render: ({ attendeesExtra2, registrations }: { attendeesExtra2: number, registrations: any[] }) => <>{calculateAttendees('attendeesExtra2', registrations)} / {attendeesExtra2}</>,
                            // sort: basicSort('attendeesExtra2'),
                        }] : []),
                        {
                            key: 6,
                            title: 'Aanwezigen',
                            render: ({ attendees, attendeesExtra, registrations }: { attendees: number, attendeesExtra: number, registrations: any[] }) => <>{!registrations?.length ? 0 : registrations?.reduce((acc, reg) => acc + (reg.attendees || 0) + (reg.attendeesExtra || 0), 0)} / {attendees + attendeesExtra}</>,
                        },
                    ]}
                    content={fetchingRegistrations ? undefined : filteredRegistrations}
                    pagination={{
                        itemsPerPage: 10,
                        page,
                        setPage
                    }}
                />

                {fetchingRegistrations && <Spinner />}

                {(!fetchingRegistrations && !registrations?.length) && <p>No registrations (yet)</p>}
            </div>

            <div className={'tfr-snackbar' + (activeRegistration?.id ? ' active' : ' inactive')}>
                <div className='tfr-snackbar-content'>
                    <button onClick={() => setActiveRegistration(undefined)} className="tfr-snackbar-close"><FontAwesomeIcon icon={faTimesCircle} /></button>

                    <h3>{activeRegistration?.customerName} ({activeRegistration?.customerId})</h3>

                    <div className="tfr-registration-info">
                        <div className="tfr-info-block">
                            <div className="tfr-info-label">Datum & Tijd:</div>
                            <div className="tfr-info-value">{formatDate(activeRegistration?.attendanceDateStart, activeRegistration?.attendanceDateEnd)}</div>
                        </div>

                        {activeRegistration?.comments && <div className="tfr-info-block">
                            <div className="tfr-info-label">Opmerkingen:</div>
                            <div className="tfr-info-value">{activeRegistration.comments}</div>
                        </div>}

                        <div className="tfr-info-block">
                            <div className="tfr-info-label">Aanwezigen:</div>
                            <TradeFairAttendanceBlock
                                tradeFair={tradeFair}
                                registration={activeRegistration}
                            />
                        </div>
                    </div>

                    <h4>Registreer</h4>

                    <div className="tfr-registration">
                        <form onSubmit={confirmRegistration}>
                            <div>
                                <label htmlFor="newreg-att">Volwassenen</label><br />
                                <input
                                    type="number"
                                    id='newreg-att'
                                    disabled={registering}
                                    min={0}
                                    value={newRegistration.attendees}
                                    onChange={(e) => setNewRegistration(prev => ({ ...prev, attendees: !e.target.value ? '' : Number(e.target.value) < 0 ? 0 : Number(e.target.value) }))}
                                    onBlur={() => setNewRegistration(prev => ({ ...prev, attendees: newRegistration.attendees || 0 }))}
                                />
                            </div>

                            {tradeFair?.attendeesExtra && <div>
                                <label htmlFor="newreg-attex">Kinderen</label><br />
                                <input
                                    type="number"
                                    id='newreg-attex'
                                    disabled={registering}
                                    min={0}
                                    value={newRegistration.attendeesExtra}
                                    onChange={(e) => setNewRegistration(prev => ({ ...prev, attendeesExtra: !e.target.value ? '' : Number(e.target.value) < 0 ? 0 : Number(e.target.value) }))}
                                    onBlur={() => setNewRegistration(prev => ({ ...prev, attendeesExtra: newRegistration.attendeesExtra || 0 }))}
                                />
                            </div>}

                            {tradeFair?.attendeesExtra2 && <div>
                                <label htmlFor="newreg-attex">Attendees Extra 2</label><br />
                                <input
                                    type="number"
                                    id='newreg-attex'
                                    disabled={registering}
                                    min={0}
                                    value={newRegistration.attendeesExtra2}
                                    onChange={(e) => setNewRegistration(prev => ({ ...prev, attendeesExtra2: !e.target.value ? '' : Number(e.target.value) < 0 ? 0 : Number(e.target.value) }))}
                                    onBlur={() => setNewRegistration(prev => ({ ...prev, attendeesExtra2: newRegistration.attendeesExtra2 || 0 }))}
                                />
                            </div>}

                            <div className="form-actions">
                                {registeringError && <span className="tfr-registration-error">{registeringError}</span>}
                                <button type="submit" disabled={registering || (!newRegistration.attendees && !newRegistration.attendeesExtra && !newRegistration.attendeesExtra2)}>{registering ? <Spinner size='small' /> : 'Registreer'}</button>

                            </div>
                        </form>
                    </div>

                    <h4>Registratie Overzicht</h4>

                    <div className="tfr-snackbar-registrations">
                        {!activeRegistration?.registrations?.length && <>Nog geen registraties.</>}

                        {activeRegistration?.registrations?.map((reg, index) => (
                            <div key={reg.timestamp + '-' + index} className="tfr-snackbar-reg">
                                <div className="tfr-snackbar-reg-content">
                                    <span className="tfr-snackbar-reg-ts">{formatDate(reg.timestamp, undefined, true)}</span>

                                    <TradeFairAttendanceBlock
                                        tradeFair={tradeFair}
                                        registration={activeRegistration}
                                        attendance={reg}
                                        hideMax
                                    />
                                </div>

                                <div className="tfr-snackbar-reg-actions">
                                    <button className="tfr-snackbar-reg-remove" onClick={() => removeRegistrationAttendance(activeRegistration, index)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
