import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './App.scss';
import {
	fetchUser,
	selectIsFetching,
	selectRoutes,
	selectUsername,
	selectUserId,
	fetchTranslations,
	selectLocale,
	selectIsTranslationsFetching,
	selectHasCorrectTranslations,
	selectError
} from './modules/user/userSlice';
import Accounts from './modules/accounts/suppliers/Accounts';
import Approvals from './modules/approvals/Approvals';
import Approval from './modules/approvals/Approval/Approval';
import Archive from './modules/archive/Archive';
import ArchiveDetail from "./modules/archive/ArchiveDetail";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect
} from 'react-router-dom';
import SplashScreen from './components/SplashScreen/SplashScreen';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigation } from '@abm-international/react-components';
import { t } from './libraries/i18n';
import Marketing from './modules/marketing/Marketing';
import InThePicture from './modules/inThePicture/InThePicture';
import InternalAccounts from './modules/accounts/internal/accounts';
import Account from './modules/accounts/suppliers/account/Account';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import config from './config';
import { TradeFairsModule } from './modules/TradeFairs';

function App() {
	const dispatch = useDispatch();
	const isUserFetching = useSelector(selectIsFetching);
	const hasUserError = useSelector(selectError);
	const isTranslationsFetching = useSelector(selectIsTranslationsFetching);
	const hasCorrectTranslations = useSelector(selectHasCorrectTranslations);
	const [isNavCollapsed, setIsNavCollapsed] = useState(false);
	const username = useSelector(selectUsername);
	const userId = useSelector(selectUserId);
	const routes = useSelector(selectRoutes);
	const locale = useSelector(selectLocale);

	useEffect(() => {
		document.cookie = `internal_client_version=${process.env.REACT_APP_APP_VERSION};max-age=9676800;`;
		dispatch(fetchUser());
	}, [dispatch]);

	useEffect(() => {
		if (!userId) return;
		dispatch(fetchTranslations(locale));
	}, [dispatch, locale, userId]);

	const isAppLoading = () => isUserFetching || isTranslationsFetching || !hasCorrectTranslations;

	// Return to portal client login if authentication failed
	if (!isUserFetching && !!hasUserError) {
		window.location.href = `${config.PORTAL_CLIENT_URL}/auth/login`;
		return <SplashScreen />;
	}

	if (isAppLoading()) return <SplashScreen />;

	const navItems = routes.map(route => ({
		...route,
		title: t(`nav.${route.title}`)
	}));

	return (
		<div className={`App ${isNavCollapsed ? 'nav--collapsed' : ''}`}>
			<Router>
				<Navigation
					collapsed={isNavCollapsed}
					toggleCollapse={() => setIsNavCollapsed(!isNavCollapsed)}
					company={{}}
					customer={{}}
					customers={[]}
					locale={'nl'}
					navItems={navItems}
					username={username}
					portalBaseUrl={process.env.REACT_APP_PORTAL_URL}
					renderLogout={() => (
						<a className={'user__item user__logout'} href={`${config.PORTAL_CLIENT_URL}/auth/logout`}>
							<div className={'item__description'}>
								{t('nav.logout')}
							</div>
							<div className={'nav__icon'}>
								<FontAwesomeIcon icon={faSignOutAlt} />
							</div>
						</a>
					)}
					renderBottom={() => null}
					t={t}
					versionNumber={'Internal Client v0.1.0'}
				/>
				<main>
					<Switch>
						<Route exact path={'/accounts'} render={() => <Redirect to='/accounts/suppliers' />} />
						<Route exact path={'/accounts/suppliers'} component={Accounts} />
						<Route path={'/trade-fairs'} component={TradeFairsModule} />
						<Route path={'/accounts/suppliers'} component={Account} />
						<Route path={'/accounts/internal'} component={InternalAccounts} />
						<Route exact path={'/approvals'} component={Approvals} />
						<Route exact path={'/approvals/archive'} component={Archive} />
						<Route path={'/approvals/archive/:product/:supplier'} component={ArchiveDetail} />
						<Route path={'/approvals/:id'} component={Approval} />
						<Route path={'/marketing'} component={Marketing} />
						<Route path={'/in-the-picture'} component={InThePicture} />
					</Switch>
				</main>
			</Router>
			<ToastContainer
				position={toast.POSITION.BOTTOM_LEFT}
				autoClose={5000}
				closeOnClick={false}
				draggable
				newestOnTop
				pauseOnHover
				pauseOnFocusLoss={true}
				className={'charles-toast__container'}
				toastClassName={'charles-toast'}
				bodyClassName={'charles-toast__body'}
				progressClassName={'charles-toast__progress'}
			>
			</ToastContainer>
		</div>
	);
}

export default App;
