import React from 'react';
import './ApprovalActions.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@abm-international/react-components';
import { t } from './../../../libraries/i18n';
import { DeclineReason, SubmissionCategory } from './../../../interfaces/approvals';

interface Props {
	isApproved: Boolean | undefined
	setIsApproved: Function
	reasons: any
	setReasons: Function
	REASONS: Array<{ id: string }>
	category: SubmissionCategory
	submissionId: string
}

export default function ApprovalActions(props: Props) {
	const {
		isApproved,
		setIsApproved,
		reasons,
		setReasons,
		REASONS,
		category,
		submissionId
	} = props;
	
	
	const getReason = (id: string): DeclineReason => reasons.find((r: DeclineReason) => r.code === id);
	const isReasonActive = (id: string): boolean => {
		const reason = getReason(id);
		return !!reason;
	}

	const setReason = (id: string, active: boolean, description?: string) => {
		const newReasons = reasons.filter((r: DeclineReason) => r.code !== id);		
		if (!active) setReasons([ ...newReasons ]);
		else setReasons([...newReasons, { code: id, description: description ?? t(`approvals.reasons_${category}_${id}`) }]);
	};

	const renderReason = (reason: { id: string }) => (
		<div key={`${submissionId}_${reason.id}`} className='form__control'>
			<label htmlFor={`reason--${submissionId}_${reason.id}`}>
				<input
					id={`reason--${submissionId}_${reason.id}`}
					type="checkbox"
					checked={isReasonActive(reason.id)}
					onChange={() => setReason(reason.id, !isReasonActive(reason.id))}
				/>
				<span className='label--content'>
					{t(`approvals.reasons_${category}_${reason.id}`)}
				</span>
			</label>
		</div>
	);

	const renderCustomReason = () => (
		<div key={`${submissionId}_0`} className='form__control'>
			<label htmlFor={`reason--${submissionId}_0`}>
				<input
					id={`reason--${submissionId}_0`}
					type="checkbox"
					checked={isReasonActive('0')}
					onChange={() => setReason('0', !isReasonActive('0'), '')}
				/>
				<span className='label--content'>
					<textarea
						className={`custom-reason__text ${isReasonActive('0') ? 'active' : ''}`}
						placeholder={t('approvals.placeholder_custom_reason')}
						value={getReason('0')?.description}
						onChange={(e) => setReason('0', true, e.target.value)}
					/>
				</span>
			</label>
		</div>
	);

	const renderWarning = () => {
		if (isApproved !== false) return null;
		
		const warnings = [];
		if (isReasonActive('0') && getReason('0')?.description.trim() === '') {
			warnings.push(t('approvals.label_warning_empty_custom_reason'));
		}

		if (reasons.length <= 0) {
			warnings.push(t('approvals.label_warning_declined_but_no_reason'));
		}

		if (warnings.length <= 0) return null;
		return (
			<ul className='warnings'>
				{warnings.map(warning => <li key={warning}>{warning}</li>)}
			</ul>
		);
	};

	return (
		<div className='ApprovalActions'>
			<div className={`action action__approve ${isApproved === true && 'active'}`}>
				<Button
					onClick={() => setIsApproved(isApproved === true ? undefined : true)}
					icon={<FontAwesomeIcon icon={faCheck} />}
					primary={isApproved === true}
					color='green'
				>
					{t('approvals.button_approve')}
				</Button>
			</div>
			<div className={`action action__deny ${isApproved === false && 'active'}`}>
				<Button
					onClick={() => setIsApproved(isApproved === false ? undefined : false)}
					icon={<FontAwesomeIcon icon={faTimes} />}
					primary={isApproved === false}
					color='red'
				>
					{t('approvals.button_deny')}
				</Button>
				{isApproved !== true && (
					<div className='reasons'>
						{REASONS.map(renderReason)}

						{renderCustomReason()}
					</div>
				)}
				{renderWarning()}
			</div>
		</div>
	);
}
