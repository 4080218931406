import useSWR, { mutate } from 'swr';
import { fetcher } from '../index';
import { transformArchiveDetails } from './index';
import {ArchiveDetailProduct} from "../../interfaces/archive";

const getProductFetchKey = (supplierId: string, productId: string) => `/manage.supplier.commercial.data/${supplierId}/articles/${productId}`;

export const mutateProduct = (supplierId: string, productId: string) => mutate(getProductFetchKey(supplierId, productId), null, true);

export default function useArchiveDetails(supplierId:string, productId: string): { product: ArchiveDetailProduct | undefined, isLoading: boolean, error: any } {
	const { data, error } = useSWR(getProductFetchKey(supplierId, productId), fetcher);

	return {
		product: data ? transformArchiveDetails(data) : undefined,
		isLoading: !error && !data,
		error
	};
}
