import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userReducer from './modules/user/userSlice';
import accountsReducer from './modules/accounts/suppliers/accountsSlice';
import suppliersReducer from './modules/suppliers/suppliersSlice';
import approvalsReducer from './modules/approvals/approvalsSlice';

export const store = configureStore({
	reducer: {
		user: userReducer,
		accounts: accountsReducer,
		suppliers: suppliersReducer,
		approvals: approvalsReducer
	},
});

(window as any).store = store;

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
