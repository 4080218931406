import React, { useEffect, useMemo, useState } from 'react';
import './Campaigns.scss';
import {
	HeroBanners,
	List,
	Button,
	TextFilter,
	CharlesTitle,
	DateTimeInput,
	ButtonLink,
	PortalModal,
	CampaignsOverview,
	CampaignDetail
} from '@abm-international/react-components';
import { useHistory } from 'react-router';
import {
	Campaign,
	CampaignBanner,
	ICampaign
} from '../../../interfaces/marketing/campaigns';
import useCampaigns from '../../../api/marketing/useCampaigns';
import {
	faBullhorn,
	faChevronDown,
	faChevronUp,
	faFile,
	faFilter,
	faImage,
	faLongArrowAltRight,
	faPencilAlt,
	faRedoAlt,
	faShoppingBasket,
	faTag,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MONTHS = [
	'Januari',
	'Februari',
	'Maart',
	'April',
	'Mei',
	'Juni',
	'Juli',
	'Augustus',
	'September',
	'Oktober',
	'November',
	'December'
];

const DAYS = [
	'Maandag',
	'Dinsdag',
	'Woensdag',
	'Donderdag',
	'Vrijdag',
	'Zaterdag',
	'Zondag'
];

const dateTranslations = {
	days: DAYS,
	months: MONTHS
};

const shortDateTranslations = {
	days: DAYS.map(day => day.slice(0,2)),
	months: MONTHS.map(month => month.slice(0,3))
};

export const getActualDayOfWeek = (date: Date) => {
	const americanWeekday = date.getDay();
	const dow = americanWeekday === 0 ? 7 : americanWeekday;
	return dow - 1; // zero indexed
};

const formatDate = (d: Date) => {
	const dayOfWeek = shortDateTranslations.days[getActualDayOfWeek(d)];
	const date = d.getDate();
	const month = shortDateTranslations.months[d.getMonth()];

	return `${dayOfWeek} ${date} ${month}`;
};

export default function Campaigns() {
	const { campaigns, error } = useCampaigns();
	const history = useHistory();
	const [date, setDate] = useState<Date | undefined>(undefined);
	const [page, setPage] = useState(0);
	const [showBanners, setShowBanners] = useState(false);
	const [showPromos, setShowPromos] = useState(false);
	const [activePromoPage, setActivePromoPage] = useState<any>('overview');

	// Initialize date
	useEffect(() => {
		const now = new Date();
		setDate(now);
	}, []);

	const activeCampaigns = useMemo((): Array<ICampaign> => {
		if (!campaigns?.length) return [];
		if (!date) return [];

		return campaigns.filter(({ start, end, active }: ICampaign) => {
			if (!active) return false;
			return start <= date && end >= date;
		});
	}, [campaigns, date]);

	const suggestedDates = useMemo((): Array<{name: string, value: Date}> => {
		if (!campaigns?.length) return [];

		const today = {
			name: 'Vandaag',
			value: new Date()
		};
		const activeCampaignsWithBannersAfterToday = campaigns.filter(({ banners, end, active }: ICampaign) => {
			if (!active) return false;
			if (!banners?.length) return false;
			return end >= today.value;
		});

		const datesAfterToday = activeCampaignsWithBannersAfterToday.map(c => ({
			name: c.name.nl,
			value: c.start
		}));

		return [
			today,
			...datesAfterToday
		].sort((day1, day2) => day1.value > day2.value ? 1 : 0).slice(0, 7);
	}, [campaigns]);

	const renderCampaignContent = ({ banners, documents, articles }: ICampaign) => {
		const badges = [];
		if (documents?.items?.length) badges.push(<span key={'documents'} className={'badge badge__documents'} title={`deze campagne heeft ${documents.items.length} documenten`} >{documents.items.length} × <FontAwesomeIcon icon={faFile} /></span>);
		if (articles?.filter) badges.push(<span key={'filter'} className={'badge badge__filter'} title={`deze campagne heeft een filter`} ><FontAwesomeIcon icon={faFilter} /></span>);
		if (articles?.badge) badges.push(<span key={'badge'} className={'badge badge__badge'} title={`deze campagne heeft een badge`} ><FontAwesomeIcon icon={faTag} /></span>);
		if (articles?.ids?.length) badges.push(<span key={'articles'} className={'badge badge__articles'} title={`deze campagne heeft ${articles.ids.length} artikelen`} >{articles.ids.length} × <FontAwesomeIcon icon={faShoppingBasket} /></span>);
		if (banners?.length) badges.push(<span key={'banners'} className={'badge badge__banners'} title={`deze campagne heeft ${banners.length} banners`} >{banners.length} × <FontAwesomeIcon icon={faImage} /></span>);

		return badges;
	};

	if (error) {
		return <>failed</>;
	}

	if (!campaigns && !error) {
		return <>loading</>;
	}

	const renderList = () => (
		<div>
			<List
				gridTemplateColumns='22rem 8rem 8rem 8rem 17rem auto 7rem'
				header={[
					{
						key: 'name',
						title: 'Naam',
						render: ({ name }: ICampaign) => name.nl,
						filter: {
							test: ({ name }: ICampaign, value: string) => name?.nl?.toLowerCase().includes(value.toLowerCase()),
							component: TextFilter
						}
					},
					{
						key: 'status',
						title: 'Status',
						render: (campaign: ICampaign) => {
							let status = '';
							const referenceDate = date?.getTime() ?? Date.now();
							if (campaign.active && campaign.start.getTime() <= referenceDate && campaign.end.getTime() >= referenceDate) status = 'active';
							else if (campaign.active && campaign.start.getTime() > referenceDate) status = 'waiting';
							else if (!campaign.active || campaign.end.getTime() < referenceDate) status = 'inactive';

							return <div className={`activeDisplay ${status}`} />;
						}
					},
					{
						key: 'weight_cover',
						title: 'Gew. Cover',
						render: (c: Campaign) => c?.documents?.cover?.weight ?? null
					},
					{
						key: 'weight_banners',
						title: 'Gew. Banners',
						render: (c: Campaign) => c?.banners?.map((b: CampaignBanner) => b?.weight).join(', ') ?? null
					},
					{
						key: 'period',
						title: 'Periode',
						render: (campaign: ICampaign) =>
							<>
								<span>{formatDate(campaign.start)}</span>
								<FontAwesomeIcon icon={faLongArrowAltRight} />
								<span>{formatDate(campaign.end)}</span>
							</>
					},
					{
						key: 'content',
						title: 'inhoud',
						render: renderCampaignContent,
					},
					{
						key: 'action',
						title: '',
						render: (c: ICampaign) => {
							if (c.legacy) return
								<Button
									icon={<FontAwesomeIcon icon={faRedoAlt} />}
									onClick={(e: any) => e.stopPropagation()}
									disabled
								/>

							return (
								<Button
									icon={<FontAwesomeIcon icon={faPencilAlt} />}
									onClick={(e: any) => {
										history.push(`/marketing/campaigns/${c.id}/edit`);
										e.stopPropagation();
									}}
								/>
							);
						},
					}
				]}
				content={campaigns?.sort((a, b) => a.end < b.end ? 1 : 0)}
				pagination={{
					itemsPerPage: 100,
					page,
					setPage
				}}
				action={(e: any, item: ICampaign) => {
					history.push(`/marketing/campaigns/${item.id}`);
				}}
			/>
		</div>
	);

	const renderBanners = () => (
		<div className={'banners'}>
			<HeroBanners
				campaigns={activeCampaigns}
				setFilter={alert}
				locale={'nl'}
				now={date?.getTime() ?? Date.now()}
			/>
		</div>
	);

	const renderDateInput = () => (
		<DateTimeInput
			translations={dateTranslations}
			onChange={setDate}
			value={date}
			options={{
				single: true,
				showSuggestions: true,
				hideSelectedDates: true
			}}
			suggestions={suggestedDates}
		/>
	);

	const renderCampaignsOverview = () => (
		<PortalModal
			close={() => {
				setShowPromos(false);
				setActivePromoPage('overview')
			}}
			className='promo-page'
		>
			{activePromoPage === 'overview' &&
				<CampaignsOverview
					campaigns={activeCampaigns}
					handleClick={setActivePromoPage}
				/>
			}
			{activePromoPage?.id &&
				<CampaignDetail
					campaign={activePromoPage}
					locale='nl'
					textBack={'Terug naar overzicht'}
					onBack={() => setActivePromoPage('overview')}
				/>
			}
		</PortalModal>
	);

	const renderSimulateDay = () => (
		<div className='simulate'>
			<h2>Simuleer een dag</h2>
			<div className='top'>
				{renderDateInput()}
				<Button
					onClick={() => setShowBanners(!showBanners)}
					icon={<FontAwesomeIcon icon={showBanners ? faChevronDown : faChevronUp} />}
					iconPosition='right'
				>
					{showBanners ? 'Verberg' : 'Toon'} Banners
				</Button>
				<Button
					onClick={() => setShowPromos(!showPromos)}
					icon={<FontAwesomeIcon icon={faBullhorn} />}
				>
					{showPromos ? 'Verberg' : 'Toon'} Folders & promoties
				</Button>
			</div>

			{showBanners && renderBanners()}
			{showPromos && renderCampaignsOverview()}
		</div>
	);

	return (
		<div className='Campaigns'>
			<CharlesTitle alt>Marketing campagnes</CharlesTitle>
			<div>
				<ButtonLink
					to='/marketing/campaigns/new'
					primary
				>
					Nieuwe campagne
				</ButtonLink>
			</div>

			{renderSimulateDay()}

			{renderList()}
		</div>
	);
}
