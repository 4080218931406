export interface MultilingualFile {
	multilingual: boolean,
	nl: string,
	fr?: string
}

export interface MultilingualText {
	nl: string,
	fr: string
}

export interface CampaignArticles {
	filter?: {
		text: MultilingualText
	},
	badge?: {
		image?: MultilingualFile,
		text: MultilingualText,
		color: {
			background: string,
			text: string
		}
	},
	dmz?: boolean,
	ids?: Array<number>,
	start?: Date,
	end?: Date,
}

export enum CampaignDocumentType {
	File = 'file',
	Url = 'url'
}

export interface CampaignDocument {
	id: string,
	type: CampaignDocumentType,
	file?: MultilingualFile,
	url?: string,
	description: MultilingualText,
	start?: Date,
	end?: Date
}

export interface ICampaignDocument extends CampaignDocument {
	hasDeviatingDates: boolean
}

export interface CampaignDocuments {
	cover: MultilingualFile & { weight: number },
	copy: MultilingualText,
	items: Array<CampaignDocument>
}

export interface ICampaignDocuments extends CampaignDocuments {
	items: Array<ICampaignDocument>
}

export enum CampaignBannerActionType {
	None = 'none',
	Document = 'document',
	Filter = 'filter'
}

export interface CampaignBanner {
	id: string,
	image: MultilingualFile,
	action: {
		type: CampaignBannerActionType,
		documentId?: string
	},
	start?: Date,
	end?: Date,
	weight: number
}

export interface BackendCampaign {
	id: string,
	active: number,
	data: {
		[prop: string]: any,
		version2: Campaign
	}
}

export interface CampaignV1 {

}

export interface Campaign {
	id: string,
	name: MultilingualText,
	start: Date,
	end: Date,
	active: boolean,
	version: string,
	legacy?: boolean,
	articles?: CampaignArticles,
	documents?: CampaignDocuments,
	banners?: Array<CampaignBanner>
}

export interface ICampaignBanner extends CampaignBanner {
	hasDeviatingDates: boolean
}

export interface ICampaign extends Campaign {
	hasArticles: boolean
	hasBadge: boolean
	badgeHasImage: boolean
	badgeImageIsMultilingual: boolean
	hasFilter: boolean
	articlesHasDeviatingDates: boolean,
	hasDocuments: boolean,
	hasBanners: boolean
	banners?: Array<ICampaignBanner>
	documents?: ICampaignDocuments
	inThePicture?: boolean
}
