import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../store';
import * as API from './../../api';
import { User, UserRoute, UserPermission } from './userInterfaces';
import * as i18n from './../../libraries/i18n';

interface UserState {
	username?: string;
	routes: Array<UserRoute>;
	permissions: Array<UserPermission>;
	isFetching: boolean;
	error?: string;
	isTranslationsFetching: boolean;
	id?: string;
	locale?: string;
	loadedTranslations: Array<string>
}

const initialState: UserState = {
	username: undefined,
	routes: [],
	permissions: [],
	isFetching: false,
	error: undefined,
	isTranslationsFetching: false,
	loadedTranslations: []
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		getUserStart: (state: UserState): void => { state.isFetching = true },
		getUserSuccess: (state: UserState, { payload }: PayloadAction<User>): void => {
			state.username = payload.username;
			state.routes = payload.routes;
			state.permissions = payload.permissions;
			state.locale = payload.locale;
			state.id = payload.id;
			state.isFetching = false;
		},
		getUserFailed: (state: UserState, { payload }: PayloadAction<string>): void => {
			state.error = payload;
			state.isFetching = false;
		},
		fetchTranslationsStart: (state: UserState): void => {
			state.isTranslationsFetching = true;
		},
		fetchTranslationsSuccess: (state: UserState, { payload }: PayloadAction<string>): void => {
			state.isTranslationsFetching = false;
			state.loadedTranslations.push(payload);
		}
	},
});

export const {
	getUserStart,
	getUserSuccess,
	getUserFailed,
	fetchTranslationsStart,
	fetchTranslationsSuccess
} = userSlice.actions;

export const fetchTranslations = (locale: string): AppThunk => async dispatch => {
	try {
		dispatch(fetchTranslationsStart());
		await i18n.init(locale);
		dispatch(fetchTranslationsSuccess(locale));
	} catch (error) {
		console.error(error);
	}
};

export const fetchUser = (): AppThunk => async dispatch => {
	try {
		dispatch(getUserStart());
		const user = await API.getUser();
		dispatch(getUserSuccess(user));
	} catch (error) {
		dispatch(getUserFailed(error.toString()));
	}
};

export const selectUsername = (state: RootState) => state.user.username;
export const selectIsFetching = (state: RootState) => state.user.isFetching;
export const selectError = (state: RootState) => state.user.error;
export const selectIsTranslationsFetching = (state: RootState) => state.user.isTranslationsFetching;
export const selectRoutes = (state: RootState) => state.user.routes;
export const selectUserId = (state: RootState) => state.user.id;
export const selectLocale = (state: RootState) => state.user.locale || 'nl';
export const selectLoadedTranslations = (state: RootState) => state.user.loadedTranslations;
export const selectHasCorrectTranslations = (state: RootState) => selectLoadedTranslations(state).includes(selectLocale(state));

export default userSlice.reducer;
