import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from './../../store';
import * as API from './../../api/approvals/index';
import { SubmitApprovalInput, DeclineReason } from './../../interfaces/approvals';
import { mutateProduct } from '../../api/approvals/useProduct';

interface ApprovalsState {
	approved: Array<{ product: string, item: string, value: boolean | undefined, approvedWithChanges: string | undefined }>
	submitting: Array<{ product: string, value: boolean | undefined }>
	reasons: Array<{ product: string, item: string, value: Array<DeclineReason> }>
	inProgress: Array<{ product: string, item: string | number }>
}

const initialState: ApprovalsState = {
	approved: [],
	submitting: [],
	reasons: [],
	inProgress: []
};

export const approvalsSlice = createSlice({
	name: 'approvals',
	initialState,
	reducers: {
		setIsApproved: (state: ApprovalsState, { payload }: PayloadAction<{ product: string, item: string, value: boolean | undefined, approvedWithChanges: string | undefined }>): void => {
			state.approved = state.approved.filter(a => a.item !== payload.item);
			state.approved.push({ ...payload });
		},
		setReasons: (state: ApprovalsState, { payload }: PayloadAction<{ product: string, item: string, value: Array<DeclineReason> }>): void => {
			state.reasons = state.reasons.filter(a => a.item !== payload.item);
			state.reasons.push({ ...payload });
		},
		setInProgress: (state: ApprovalsState, { payload }: PayloadAction<{product: string, item: string | number}>): void => {
			state.inProgress.push({...payload});
		},
		removeInProgress: (state: ApprovalsState, { payload }: PayloadAction<{product: string, item: string | number}>): void => {
			state.inProgress = state.inProgress.filter(a => a.item !== payload.item && a.product !== payload.product);
		},
		submitApprovalStart: (state: ApprovalsState, { payload }: PayloadAction<string>): void => {
			state.submitting = state.submitting.filter(a => a.product !== payload);
			state.submitting.push({ product: payload, value: true });
		},
		submitApprovalSuccess: (state: ApprovalsState, { payload }: PayloadAction<string>): void => {
			state.submitting = state.submitting.filter(a => a.product !== payload);
			state.submitting.push({ product: payload, value: false });
			state.approved = state.approved.filter(a => a.product !== payload);
			state.reasons = state.reasons.filter(r => r.product !== payload);
		},
		submitApprovalFailed: (state: ApprovalsState, { payload }: PayloadAction<{ product: string, error: string }>): void => {
			state.submitting = state.submitting.filter(a => a.product !== payload.product);
			state.submitting.push({ product: payload.product, value: false });
		}
	},
});

export const {
	setIsApproved,
	setReasons,
	setInProgress,
	removeInProgress,
	submitApprovalStart,
	submitApprovalSuccess,
	submitApprovalFailed
} = approvalsSlice.actions;

export const submitApproval = (input: SubmitApprovalInput): AppThunk => async dispatch => {
	try {
		dispatch(submitApprovalStart(input.article));
		await API.submitApproval(input);
		dispatch(submitApprovalSuccess(input.article));
		mutateProduct(input.article);
	} catch (error) {
		dispatch(submitApprovalFailed({ product: input.article, error: error?.toString() || 'Unknown error' }));
		mutateProduct(input.article);
	}
};

export const selectIsItemApproved = (state: RootState, item: string) =>
	state.approvals.approved.filter(a => a.item === item)?.[0]?.value ?? undefined;

export const selectItemDeclinedReasons = (state: RootState, item: string) =>
	state.approvals.reasons.find(a => a.item === item)?.value ?? [];

export const selectApprovalData = (state: RootState, product: string): SubmitApprovalInput => {
	const approved = state.approvals.approved
		.filter(a => a.product === product && a.value === true)
		.map(a => ({ id: a.item.split('.')[1], approvedWithChanges: a.approvedWithChanges }));

	const declined = state.approvals.approved
		.filter(a => a.product === product && a.value === false)
		.map(a => ({
			id: a.item.split('.')[1],
			reasons: selectItemDeclinedReasons(state, a.item)
		}));

	const inProgress = state.approvals.inProgress;

	return {
		article: product,
		approved,
		declined,
		inProgress
	};
};

export default approvalsSlice.reducer;
