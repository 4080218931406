import { useMemo } from 'react';
import './TradeFairAttendanceBlock.scss';
import { TradeFair, TradeFairRegistration, TradeFairRegistrationAttendance } from '../index.types';
type Attendance = number | {
    value: number;
    max?: number;
    label?: string;
}

interface TradeFairAttendanceBlockProps {
    tradeFair?: TradeFair;
    registration?: TradeFairRegistration;
    attendance?: TradeFairRegistrationAttendance;
    hideMax?: boolean;
}

export const calculateAttendees = (type: keyof Omit<TradeFairRegistrationAttendance, 'timestamp'>, registrations?: TradeFairRegistrationAttendance[]) => {
    return registrations?.reduce((acc, reg) => acc + (reg[type] || 0), 0) || 0;
}

export const TradeFairAttendanceBlock = (props: TradeFairAttendanceBlockProps) => {
    const { tradeFair, registration, attendance, hideMax } = props;

    const attendances: Attendance[] = useMemo(() => {
        const _attendances = [{
            value: attendance?.attendees !== undefined ? attendance?.attendees : calculateAttendees('attendees', registration?.registrations),
            max: registration?.attendees,
            label: 'volwassenen'
        }];

        if (tradeFair?.attendeesExtra) {
            _attendances.push({
                value: attendance?.attendeesExtra !== undefined ? attendance?.attendeesExtra : calculateAttendees('attendeesExtra', registration?.registrations),
                max: registration?.attendeesExtra,
                label: 'kinderen'
            });
        }

        if (tradeFair?.attendeesExtra2) {
            _attendances.push({
                value: attendance?.attendeesExtra2 !== undefined ? attendance?.attendeesExtra2 : calculateAttendees('attendeesExtra2', registration?.registrations),
                max: registration?.attendeesExtra2,
                label: 'attendances extra 2'
            });
        }

        return _attendances;
    }, [tradeFair, registration, attendance])

    return (
        <div className="TradeFairAttendanceBlock">
            {attendances.map((attendance, index) => (
                <div key={'attendance-' + index} className='attendance'>
                    <div className='attendance-count'>
                        {typeof attendance === 'number' ? !!attendance : attendance.value}
                        {!hideMax && typeof attendance !== 'number' && attendance.max !== undefined && <> / {attendance.max}</>}
                    </div>

                    {typeof attendance !== 'number' && attendance.label && <div className='attendance-label'>
                        {attendance.label}
                    </div>}
                </div>
            ))}
        </div>
    )
}
