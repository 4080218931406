import fetch from 'cross-fetch';
import config from './../config';
import { logger } from './logger/logger';

const resToString = res => {
	if (res.toString && res.toString() !== '{}') return res.toString();
	if (res.message) return res.message;
	return res;
};

export const get = (endpoint) => method('GET', endpoint);
export const post = (endpoint, body) => method('POST', endpoint, body);

export const method = (method, endpoint, body) => {
	return new Promise((resolve, reject) => {
		try {
			checkMaintenanceMode();
		} catch (error) {
			reject(error);
		}

		method = method.toUpperCase();
		const options = {
			credentials: 'include',
			method: method
		};

		let bodyObject = body;

		if (body) {
			options.body = body;

			try {
				bodyObject = JSON.parse(body);
			} catch (error) {
				logger.verbose('body is not JSON, cannot convert', {
					body: body
				});
			}
		}

		fetch(config.API_URL + endpoint, options)
			.then(res => {
				return res.json().then(json => {
					return {
						result: res,
						body: json
					};
				});
			})
			.then(res => {
				if (bodyObject && bodyObject.password) {
					bodyObject.usingPassword = !!bodyObject.password;
					delete bodyObject.password;
				}

				logger.debug(`${method} [${res.result.status}] ${endpoint}`, {
					endpoint: config.API_URL + endpoint,
					body: bodyObject ? bodyObject : 'The request has no body',
					options: options,
					res: res
				});

				if (res.result.status >= 400) {
					if (res.result.status === 401 || res.body?.error_code === 1003) window.location.href = config.LOGIN_URL;
					if (res.result.status === 503) {
						const url = new URL(window.location.href);
						url.searchParams.append('maintenance', true);
						window.location.href = url;
					}

					return reject(res);
				}

				return resolve(res.body);
			})
			.catch(res => {
				if (bodyObject && bodyObject.password) {
					bodyObject.usingPassword = !!bodyObject.password;
					delete bodyObject.password;
				}

				logger.error(`${method} ERROR ${endpoint}`, {
					endpoint: config.API_URL + endpoint,
					body: bodyObject ? bodyObject : 'This request was sent without a body (this is not an error in itself)',
					options: options,
					res: resToString(res)
				});

				return reject(res);
			});
	});
};

const checkMaintenanceMode = () => {
	const urlParams = new URLSearchParams(window.location.search);
	const maintenance = urlParams.get('maintenance');
	if (maintenance || maintenance === '') return new Error('Maintenance mode active');
};

export const authenticate = (username, password) => {
	return new Promise((resolve, reject) => {
		post('/login', JSON.stringify({
			username: username,
			password: password
		}))
			.then(res => {
				if (!res.success) {
					return reject(res);
				}

				if (res.session && res.session.userId) {
					return resolve({
						id: res.session.userId,
						permissions: []
					});
				} else {
					return reject(res);
				}
			})
			.catch(reject);
	});
};

export const isAuthenticated = () => {
	return new Promise((resolve, reject) => {
		get('/me')
			.then(resolve)
			.catch(reject);
	});
};

export const logout = () => {
	return new Promise((resolve, reject) => {
		post('/logout')
			.then(res => {
				logger.info('Logout successful', {
					res: res
				});
				localStorage.clear();
				resolve('logged out');
			})
			.catch(res => {
				logger.error('Logout ERROR', {
					res: res
				});
				reject('failed');
			});
	});
};

// eslint-disable-next-line
export default {
	get,
	post,
	method,
	authenticate,
	isAuthenticated,
	logout,
};
