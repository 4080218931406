import { useMemo } from "react";
import useSWR from 'swr';
import { DEFAULT_AXIOS_CONFIG, fetcher } from './../index';
import {
	BackendCampaign,
	CampaignArticles,
	CampaignBanner,
	CampaignDocuments,
	ICampaign,
	ICampaignBanner,
	ICampaignDocument,
	MultilingualFile
} from './../../interfaces/marketing/campaigns';
import { LATEST_VERSION, transformCampaign } from './index';
import axios from 'axios';

const getMultilingualFile = (file: any): MultilingualFile => {
	if (file.multilingual) {
		return {
			multilingual: true,
			nl: file.nl
		};
	}

	return {
		multilingual: false,
		nl: file.nl,
		fr: file.fr
	};
};

const getArticlesData = (c: ICampaign): CampaignArticles => ({
	filter: c.hasFilter ? c.articles?.filter : undefined,
	badge: c.hasBadge && c.articles?.badge ? {
		text: c.articles?.badge?.text,
		color: c.articles?.badge?.color,
		image: c.badgeHasImage ? getMultilingualFile(c.articles?.badge?.image) : undefined
	} : undefined,
	dmz: c.articles?.dmz,
	ids: c.articles?.ids,
	start: c.articlesHasDeviatingDates ? c.articles?.start : undefined,
	end: c.articlesHasDeviatingDates ? c.articles?.end : undefined
});

const getDocumentsData = (c: ICampaign): CampaignDocuments | undefined => {
	if (!c.documents) return undefined;

	const getItems = (items: Array<ICampaignDocument>) => items?.map((item: ICampaignDocument) => ({
		id: item.id,
		type: item.type,
		file: item.file,
		url: item.url,
		description: item.description,
		start: item.hasDeviatingDates ? item.start : undefined,
		end: item.hasDeviatingDates ? item.end : undefined
	}));

	return {
		cover: c.documents.cover,
		copy: c.documents.copy,
		items: getItems(c.documents.items)
	};
};

const getVersion1 = (campaign: ICampaign): any => {
	return {
		id: campaign.id,
		badge: {
			nl: campaign.articles?.badge?.text?.nl,
			fr: campaign.articles?.badge?.text?.fr,
			color: {
				text: campaign.articles?.badge?.color?.text,
				bg: campaign.articles?.badge?.color?.background,
			}
		},
		filter: {
			nl: campaign.articles?.filter?.text?.nl,
			fr: campaign.articles?.filter?.text?.fr
		},
		articles: campaign.articles?.ids,
		start: campaign.start,
		end: campaign.end,
		active: campaign.active,
		weight: 0,
		version: LATEST_VERSION
	};
};

const getBanners = (campaign: ICampaign): Array<CampaignBanner> | undefined => campaign?.banners?.map((item: ICampaignBanner) => ({
	id: item.id,
	image: item.image,
	action: item.action,
	start: item.hasDeviatingDates ? item.start : undefined,
	end: item.hasDeviatingDates ? item.end : undefined,
	weight: item.weight
}));

const getSaveableCampaign = (c: ICampaign): BackendCampaign => ({
	id: c.id,
	active: c.active ? 1 : 0,
	data: {
		...getVersion1(c),
		version2: {
			id: c.id,
			name: c.name,
			start: c.start,
			end: c.end,
			version: LATEST_VERSION,
			active: c.active,
			articles: c.hasArticles ? getArticlesData(c) : undefined,
			documents: c.hasDocuments ? getDocumentsData(c) : undefined,
			banners: c.hasBanners ? getBanners(c) : undefined
		}
	}
});

export const saveCampaign = async (c: ICampaign) => {
	try {
		const campaign = getSaveableCampaign(c);
		const result = await axios.post(`/sales/campaigns/${c.id}`, campaign, DEFAULT_AXIOS_CONFIG);
		return result?.data;
	} catch (error) {
		return error;
	}
};

export const deleteCampaign = async (id: string) => {
	try {
		const result = await axios.post(`/sales/campaigns/${id}/delete`, null, DEFAULT_AXIOS_CONFIG);
		return result?.data;
	} catch (error) {
		return error;
	}
}

const DEFAULT_NEW_CAMPAIGN = {
	id: 'new',
	version: LATEST_VERSION,
	name: {
		nl: '',
		fr: ''
	},
	start: new Date(),
	end: new Date(),
	weight: 0,
	active: false,
	articles: undefined,
	documents: undefined,
	banners: undefined,
	hasArticles: false,
	hasBadge: false,
	badgeHasImage: false,
	badgeImageIsMultilingual: false,
	hasFilter: false,
	articlesHasDeviatingDates: false,
	hasDocuments: false,
	hasBanners: false
};

export default function useCampaign(id: string | null): { campaign?: ICampaign, error: any, mutate: any } {
	const { data, error, mutate } = useSWR(id && `/sales/campaigns/${id}`, fetcher);

	const campaign = useMemo(() => data ? transformCampaign(data) : undefined, [data]);

	const emptyCampaign = useMemo(() => DEFAULT_NEW_CAMPAIGN, []);

	return {
		campaign: id ? campaign : emptyCampaign,
		error,
		mutate
	};
}
