import React, { useState } from 'react';
import './Campaigns.scss';
import {
	List,
	Button,
	TextFilter,
	CharlesTitle,
	ButtonLink,
} from '@abm-international/react-components';
import { useHistory } from 'react-router';
import {
	ICampaign
} from '../../../interfaces/marketing/campaigns';
import useCampaigns from '../../../api/inThePicture/useCampaigns';
import {
	faLongArrowAltRight,
	faPencilAlt,
	faRedoAlt,
	faShoppingBasket,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MONTHS = [
	'Januari',
	'Februari',
	'Maart',
	'April',
	'Mei',
	'Juni',
	'Juli',
	'Augustus',
	'September',
	'Oktober',
	'November',
	'December'
];

const DAYS = [
	'Maandag',
	'Dinsdag',
	'Woensdag',
	'Donderdag',
	'Vrijdag',
	'Zaterdag',
	'Zondag'
];


const shortDateTranslations = {
	days: DAYS.map(day => day.slice(0,2)),
	months: MONTHS.map(month => month.slice(0,3))
};

export const getActualDayOfWeek = (date: Date) => {
	const americanWeekday = date.getDay();
	const dow = americanWeekday === 0 ? 7 : americanWeekday;
	return dow - 1; // zero indexed
};

const formatDate = (d: Date) => {
	const dayOfWeek = shortDateTranslations.days[getActualDayOfWeek(d)];
	const date = d.getDate();
	const month = shortDateTranslations.months[d.getMonth()];

	return `${dayOfWeek} ${date} ${month}`;
};

export default function Campaigns() {
	const { campaigns, error } = useCampaigns();
	const history = useHistory();
	const [page, setPage] = useState(0);

	const renderCampaignContent = ({ articles }: ICampaign) => {
		const badges = [];
		if (articles?.ids?.length) badges.push(<span key={'articles'} className={'badge badge__articles'} title={`deze campagne heeft ${articles.ids.length} artikelen`} >{articles.ids.length} × <FontAwesomeIcon icon={faShoppingBasket} /></span>);
		return badges;
	};

	if (error) {
		return <>failed</>;
	}

	if (!campaigns && !error) {
		return <>loading</>;
	}

	const renderList = () => (
		<div>
			<List
				gridTemplateColumns='22rem 8rem 17rem auto 7rem'
				header={[
					{
						key: 'name',
						title: 'Naam',
						render: ({ name }: ICampaign) => name.nl,
						filter: {
							test: ({ name }: ICampaign, value: string) => name?.nl?.toLowerCase().includes(value.toLowerCase()),
							component: TextFilter
						}
					},
					{
						key: 'status',
						title: 'Status',
						render: (campaign: ICampaign) => {
							let status = '';
							const referenceDate = Date.now();
							if (campaign.active && campaign.start.getTime() <= referenceDate && campaign.end.getTime() >= referenceDate) status = 'active';
							else if (campaign.active && campaign.start.getTime() > referenceDate) status = 'waiting';
							else if (!campaign.active || campaign.end.getTime() < referenceDate) status = 'inactive';

							return <div className={`activeDisplay ${status}`} />;
						}
					},
					{
						key: 'period',
						title: 'Periode',
						render: (campaign: ICampaign) =>
							<>
								<span>{formatDate(campaign.start)}</span>
								<FontAwesomeIcon icon={faLongArrowAltRight} />
								<span>{formatDate(campaign.end)}</span>
							</>
					},
					{
						key: 'content',
						title: 'inhoud',
						render: renderCampaignContent,
					},
					{
						key: 'action',
						title: '',
						render: (c: ICampaign) => {
							if (c.legacy) {
								return (
									<Button
										icon={<FontAwesomeIcon icon={faRedoAlt} />}
										onClick={(e: any) => e.stopPropagation()}
										disabled
									/>
								);
							}

							return (
								<Button
									icon={<FontAwesomeIcon icon={faPencilAlt} />}
									onClick={(e: any) => {
										history.push(`/in-the-picture/campaigns/${c.id}/edit`);
										e.stopPropagation();
									}}
								/>
							);
						},
					}
				]}
				content={campaigns?.sort((a, b) => a.end < b.end ? 1 : 0)}
				pagination={{
					itemsPerPage: 100,
					page,
					setPage
				}}
				action={(e: any, item: ICampaign) => {
					history.push(`/in-the-picture/campaigns/${item.id}`);
				}}
			/>
		</div>
	);



	return (
		<div className='Campaigns'>
			<CharlesTitle alt>In de kijker</CharlesTitle>
			<div>
				<ButtonLink
					to='/in-the-picture/campaigns/new'
					primary
				>
					Nieuwe campagne
				</ButtonLink>
			</div>

			{renderList()}
		</div>
	);
}
