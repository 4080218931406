export enum SubmissionStatus {
	SUBMITTED = 10,
	REMOVED = 97,
	INVALID = 98,
	REJECTED = 99,
	APPROVED = 100
}

export interface Submission {
	id: string
	status: SubmissionStatus
	since: Date
	value: string
	type: SubmissionType
	subtype?: number
	version?: number
	category: SubmissionCategory
	supplier: string
	by: string,
	active?: boolean;
	letCharlesDoIt?: boolean;
}

export enum SubmissionCategory {
	TEXT = 'text',
	IMAGE = 'images'
}

export enum SubmissionType {
	NL = 'nl',
	FR = 'fr',
	EN = 'en',
	DE = 'de',
	PACKSHOT = 'PS',
	MOODSHOT = 'SF'
}

export interface ProposalProduct {
	id: string
	code: string
	name?: string
	imageUrl: string
	supplier: {
		code: string
		name: string
	}
	submissions: Array<Submission>
}

export interface DeclineReason {
	code: string,
	description: string
}

export interface SubmitApprovalInput {
	article: string
	approved: Array<{ id: string, approvedWithChanges?: string | undefined }>
	declined: Array<{ id: string, reasons: Array<DeclineReason> }>
	inProgress: Array<{ product: string, item: string | number }>
}
