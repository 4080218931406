import useSWR from 'swr';
import { fetcher } from './../index';

export default function useArticles(): any {
	const { data, error } = useSWR('/sales/campaigns/articles', fetcher);

	return {
		articles: data,
		error,
		isLoading: !data && !error
	};
}
