import { useEffect, useState } from "react";
import { TradeFair } from "../index.types";
import { get } from "../../../libraries/api";
import { TradeFairInfoBlock } from "../TradeFairInfoBlock";
import { Spinner } from '@abm-international/react-components';

import './TradeFairsOverview.scss'

const URL = '/api/v1/trade-fairs';

export const TradeFairsOverview = () => {
    const [tradeFairs, setTradeFairs] = useState<TradeFair[]>();
    const [fetching, setFetching] = useState(false);

    useEffect(() => {
        setFetching(true);

        (async () => {
            const response = await get(`${URL}`);
            if (response.success) {
                setTradeFairs(response.data);
            }
            setFetching(false);
        })();
    }, []);

    return (
        <div className="TradeFairsOverview">
            <h1 className="page-title">Evenementen</h1>

            <div className="list">
                {fetching && <Spinner />}

                {tradeFairs?.map((tradeFair) => (
                    <TradeFairInfoBlock
                        key={tradeFair.id}
                        {...tradeFair}
                    />
                ))}
            </div>
        </div>
    );
}
