import { List, CharlesTitle, ButtonLink } from '@abm-international/react-components';
import { CRUDField, CRUDFieldTypes as CRUDFieldType, CRUDListConfig } from '../../interfaces/crud';
import useSWR from 'swr';
import { useHistory, useRouteMatch } from 'react-router';
import './CRUDList.scss';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetcher } from './../../api/index';

const getColumnRenderer = (type: CRUDFieldType | string): any => {
	switch (type) {
		case CRUDFieldType.Text:
		case CRUDFieldType.Number:
		default:
			return (item: any, key: string) => item[key];
	}
};

const getColumns = (data: Array<string>, fields: Array<CRUDField>): any =>
	data.map((col: string) => {
		const field = fields.find((field: CRUDField) => field.name === col);
		if (!field) return undefined;

		return {
			key: field.name,
			title: field.title,
			render: (item: any) => getColumnRenderer(field.type)(item, field.name)
		};
	});

const useListItems = (url: string) => {
	const { data, error } = useSWR(url, fetcher);
	let errorData;

	if (data?.error_code) {
		errorData = data;
	}

	return {
		items: errorData ? undefined : data,
		error: errorData ?? error
	}
};

interface Props {
	fields: Array<CRUDField>,
	config: CRUDListConfig,
	t: (translationCode: string, params?: any) => string
}

export default function CRUDList(props: Props) {
	const { items, error } = useListItems(props.config.fetch);
	
	const history = useHistory();
	const { path } = useRouteMatch();
	const { t } = props;

	const renderList = () => (
		<List
			header={getColumns(props.config.columns, props.fields)}
			content={items}
			action={(e: any, item: any) => {
				e.stopPropagation();
				history.push(`${path}/${item.id}`);
			}}
		/>
	);

	const renderError = () => (
		<div className='message error'>
			<span role='img' aria-label='error emoji'>💥</span>
			<p>{error?.error_message ?? t('crud.error_fetching_list_items')}</p>
		</div>
	);

	return (
		<div className='CRUDList'>
			<CharlesTitle alt>{t('crud.title_list')}</CharlesTitle>

			<ButtonLink
				className={'new-item'}
				icon={<FontAwesomeIcon icon={faPlus} />}
				to={`${path}/new`}
				primary
			>
				{t('crud.button_new_item')}
			</ButtonLink>

			{error && renderError()}
			{items && renderList()}
		</div>
	);
}
