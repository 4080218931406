import React from 'react';
import './SubmissionStatusBadge.scss';
import { t } from './../../../libraries/i18n';
import { SubmissionStatus } from './../../../interfaces/approvals';

interface Props {
	status?: SubmissionStatus,
	since?: Date,
	by?: string,
	size?:'small'
}

// For better classNames
const STATUSES = {
	10: 'SUBMITTED',
	97: 'REMOVED',
	98: 'INVALID',
	99: 'REJECTED',
	100: 'APPROVED'
};

// TODO: Move to @abm-international/libraries
const formatDate = (date: Date) => {
	const day = date.getDate().toString().padStart(2, '0');
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const year = date.getFullYear();

	const hours = date.getHours().toString().padStart(2, '0');
	const minutes = date.getMinutes().toString().padStart(2, '0');

	return `${day}/${month}/${year} ${hours}:${minutes}`;
};

export default function SubmissionStatusBadge(props: Props) {
	const {
		status,
		since,
		by
	} = props;

	return (
		<div className='SubmissionStatusBadge'>
			{status && (
				<span className={`version__status ${STATUSES[status]}`}>
					{t(`approvals.status_${status}`)}
				</span>
			)}
			{since && (
				<span className='version__since'>
					{props.size !== 'small' ? t('approvals.label_since', formatDate(since)) : formatDate(since)}
				</span>
			)}
			{by && (
				<span className='version__by'>
					{props.size !== 'small' && t('approvals.label_by', '')}
					<span className='name'>{by}</span>
				</span>
			)}
		</div>
	);
}
