import {
	BackendCampaign,
	CampaignBannerActionType,
	CampaignDocumentType,
	ICampaign,
} from './../../interfaces/marketing/campaigns';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import semverLt from 'semver/functions/lt';

const upgradeVersion1ToVersion2 = (campaign: any) => {
	const getArticles = () => {
		if (!campaign.articles) return null;

		return {
			ids: Array.isArray(campaign.articles) ? campaign.articles : undefined,
			dmz: campaign.articles === 'dmz',
			filter: {
				text: campaign.filter
			},
			badge: campaign.badge ? {
				image: false,
				text: {
					nl: campaign.badge.nl,
					fr: campaign.badge.fr
				},
				color: {
					text: campaign.badge.color.text,
					background: campaign.badge.color.bg
				}
			} : null
		};
	};

	const getDocuments = () => {
		if (!campaign.folder && !campaign.flyer && !campaign.rules && !campaign.posters && !campaign.recipes) return false;

		const getItems = () => {
			const sections = [
				campaign.folder,
				campaign.flyer,
				campaign.rules,
				campaign.posters,
				campaign.recipes
			];

			return sections.flatMap((section: any) => {
				if (!section) return [];

				let nlItems: any = Array.isArray(section.nl) ? section.nl : [section.nl];
				let frItems: any = Array.isArray(section.fr) ? section.fr : [section.fr];

				return nlItems?.map((item: any, index: number) => {
					const frItem = frItems?.[index];

					return {
						id: uuidv4(),
						type: CampaignDocumentType.File,
						description: {
							nl: item.text,
							fr: frItem?.text
						},
						file: {
							multilingual: false,
							nl: item.file,
							fr: frItem?.file
						}
					};
				});
			});
		};

		return {
			cover: null,
			copy: null,
			items: getItems()
		};
	};

	const getBanners = () => {
		if (!campaign.banners) return null;

		const getActionType = (type: string | undefined) => {
			if (!type) return CampaignBannerActionType.None;
			if (type === 'filter') return CampaignBannerActionType.Filter;
			return CampaignBannerActionType.Document;
		};

		const getDocumentId = (item: any) => {
			// This is actually really complicated,
			// not in the mood
			return null;
		};

		const getBanner = (item: any) => {
			if (!item) return;
			return {
				id: uuidv4(),
				image: {
				multilingual: false,
					nl: `${campaign.id}/${item.nl}`,
					fr: `${campaign.id}/${item.fr}`
				},
				action: item.action ? {
					type: getActionType(item.action.type),
					documentId: getDocumentId(item)
				} : {
					type: CampaignBannerActionType.None
				}
			}
		};

		const banners = [
			...[campaign.banners.horizontal].flat()?.map(getBanner) ?? [],
			...[campaign.banners.vertical].flat()?.map(getBanner) ?? []
		];

		return banners.filter(b => b);
	};

	return {
		id: campaign.id,
		version: '2.0.0',
		legacy: true,
		name: {
			nl: campaign.id,
			fr: ''
		},
		start: campaign.start,
		end: campaign.end,
		active: campaign.active,
		weight: campaign.weight,
		articles: getArticles(),
		documents: getDocuments(),
		banners: getBanners()
	};
};

const upgradeVersion2ToVersion2_1 = (campaign: any) => {
	const { weight, ...propsToKeep } = campaign;

	return {
		...propsToKeep,
		documents: campaign?.documents ? {
			...campaign?.documents,
			cover: campaign?.documents?.cover ? {
				...campaign.documents?.cover,
				weight: campaign?.weight ?? 0
			} : undefined
		} : undefined,
		banners: campaign?.banners?.map((b: any) => ({ ...b, weight: campaign.weight })),
		version: '2.1.0'
	};
};

const VERSION_UPGRADERS: Array<{ version: string, upgrade: (c: any) => any }> = [
	{
		version: '2.0.0',
		upgrade: upgradeVersion1ToVersion2
	},
	{
		version: '2.1.0',
		upgrade: upgradeVersion2ToVersion2_1
	}
];

// Check https://semver.org/
export const LATEST_VERSION = '2.1.0';

const upgrade = (campaign: any): ICampaign => VERSION_UPGRADERS.reduce((campaign, upgrader, index) => {
	const currentVersion = (campaign?.version ?? '1.0.0');
	const nextVersion = upgrader.version;
	if (semverLt(currentVersion, nextVersion)) return upgrader.upgrade(campaign);
	return campaign;
}, campaign);

export const transformCampaign = (data: BackendCampaign): ICampaign => {
	let c: any = {
		id: data.id,
		active: data.active === 1,
	};

	if (data.data.version2) c = { ...c, ...data.data.version2 };
	else c = { ...c, ...data.data };

	if ((c?.version ?? 1) !== LATEST_VERSION) c = upgrade(c);
	const hasBadge = !!c?.articles?.badge;
	const badgeHasImage = hasBadge && !!c?.articles?.badge?.image?.nl;
	const hasArticles = hasBadge || (c?.articles?.ids?.length ?? 0) > 0;

	return {
		...c,
		hasArticles,
		hasBadge,
		badgeHasImage,
		badgeImageIsMultilingual: badgeHasImage && (!!c?.articles?.badge?.image?.fr),
		start: new Date(c.start),
		end: new Date(c.end),
		articles: c.articles ? {
			...c.articles,
			start: c.articles.start ? new Date(c.articles.start) : undefined,
			end: c.articles.end ? new Date(c.articles.end) : undefined,
		} : undefined
	};
};

export const uploadFile = (data: any, campaignId: string, name: string) => axios.post(
	`/api/sales/campaigns/${campaignId}/file/${name}`,
	data
);
