import useSWR from 'swr';
import { fetcher } from './../index';

export default function useMetadata(): { photoLocation: any, isLoading: boolean, error: any } {
	const { data, error } = useSWR(`/manage.supplier.commercial.data/metadata`, fetcher);

	return {
		photoLocation: data?.categories.images.location,
		isLoading: !error && !data,
		error
	};
}
