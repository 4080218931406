import React, { useState } from 'react';
import './ArticlesInput.scss';
import { FlextArea, Button } from '@abm-international/react-components';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useArticles from '../../../api/marketing/useArticles';

export default function ArticlesInput(props: any) {
	const [text, setText] = useState('');
	const { articles, isLoading } = useArticles();

	const handleTextSubmit = () => {
		const iterator = text.matchAll(/([0-9]+)/g)
		const matches = Array.from(iterator)
			.flatMap(m => parseInt(m[0]));
		const allIds = (props.value ?? []).concat(matches);
		const newValue = [...Array.from(new Set(allIds))];
		props.onChange(newValue);
		setText('');
	};

	const handleDelete = (deleteId: number) => props.onChange(props.value.filter((id: number) => id !== deleteId));

	const renderArticleName = (id: number) => {
		if (isLoading) {
			return <span className='article__loading'></span>;
		}

		const article = articles?.[id];
		const doesExist = !!article;
		const name = article?.article?.S?.description?.nl;		

		if (!doesExist) {
			return (
				<span className='article__unknown'>
					onbekend artikel
				</span>
			);
		}

		return (
			<span className='article__name'>
				{name}
			</span>
		);
	};

	const renderArticle = (id: number) => {
		const article = articles?.[id];
		const doesExist = !!article;
		
		return (
			<div key={id} className={`article ${doesExist ? '' : 'unknown'}`}>
				<span className='article__body'>
					<span className='article__id'>{id}</span>
					{renderArticleName(id)}
				</span>
				<FontAwesomeIcon icon={faTrashAlt} onClick={() => handleDelete(id)} />
			</div>
		);
	};

	const renderArticles = () => props?.value && (
		<div className='articles'>
			{props.value?.map(renderArticle)}
		</div>
	);

	return (
		<div className='ArticlesInput'>
			{renderArticles()}
			<FlextArea
				value={text}
				onChange={setText}
				disabled={props.disabled}
			/>
			<Button
				color={'green'}
				icon={<FontAwesomeIcon icon={faPlus} />}
				className='add'
				onClick={handleTextSubmit}
				disabled={props.disabled}
			>
				Toevoegen
			</Button>
		</div>
	);
}
