import axios from 'axios';

export const DEFAULT_AXIOS_CONFIG = {
	withCredentials: true,
	baseURL: `${process.env.REACT_APP_PORTAL_URL}/api`,
	headers: {
		'Content-Type': 'application/json'
	}
};

export async function fetcher(url: string) {
	const { data } = await axios.get(url, DEFAULT_AXIOS_CONFIG);
	return data;
}
