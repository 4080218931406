import useSWR, { mutate } from 'swr';
import { fetcher } from '../index';
import {ArchiveItem, Article, Supplier} from '../../interfaces/archive';
import { transformItems } from './index';
import { SubmissionStatus } from '../../interfaces/approvals';

const getProductFetchKey = () => `/supplier.commercial.data.archive/?all`;

export const mutateProduct = () => mutate(getProductFetchKey());

interface Uniques {
	statuses: Array<SubmissionStatus>;
	types: Array<string>; // : SubmissionCategory_SubmissionType
	suppliers: Array<string>;
	articles: Array<string>;
	registeredUsers: Array<string>;
	processedUsers: Array<string>;
}


export default function useArchive(): { items: Array<ArchiveItem> | undefined, uniques: Uniques, isLoading: boolean, error: any } {
	const { data, error } = useSWR(getProductFetchKey(), fetcher);

	const items = data ? transformItems(data) : undefined

	const initial: Uniques = {
		statuses: [],
		types: [],
		// @ts-ignore
		suppliers: Object.entries(data?.suppliers ?? {}).map(([id, supplier]: [string, Supplier]) => `${id} ${supplier.name ?? ''}`),
		// @ts-ignore
		articles: Object.entries(data?.articles ?? {}).map(([id, article]: [string, Article]) => `${id} ${article.S.description.nl ?? ''}`),
		registeredUsers: [],
		processedUsers: []
	};


	const uniques =  items?.reduce(
		(
			set,
			{ status, type, category, registered, processed }
		) => {
			if (!set.statuses.includes(status)) set.statuses.push(status);
			if (!set.types.includes(`${category}_${type}`)) set.types.push(`${category}_${type}`);
			if (!set.registeredUsers.includes(registered.by)) set.registeredUsers.push(registered.by);
			if (processed.by && !set.processedUsers.includes(processed.by)) set.processedUsers.push(processed.by);
			return set;
		}, initial
	) ?? initial;

	return {
		items,
		uniques,
		isLoading: !error && !data,
		error
	};
}
