import { useMemo } from 'react';
import useSWR, { mutate } from 'swr';
import { fetcher } from './../index';
import {
	ICampaign,
} from './../../interfaces/marketing/campaigns';
import { transformCampaign } from './index';

const CAMPAIGN_ENDPOINT = '/sales/campaigns?all';

export const mutateCampaigns = () => mutate(CAMPAIGN_ENDPOINT);

export default function useCampaigns(): { campaigns?: Array<ICampaign>, error: any } {
	const { data, error } = useSWR(CAMPAIGN_ENDPOINT, fetcher);

	const campaigns = useMemo(() => data?.map(transformCampaign).filter(({ inThePicture }: ICampaign) => inThePicture !== true), [data])

	return {
		campaigns,
		error
	};
}
