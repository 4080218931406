import React from 'react';
import './DocumentsInput.scss';
import { DynamicForm, Button } from '@abm-international/react-components';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';
import { CampaignDocument } from '../../../interfaces/marketing/campaigns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const getNewEmptyItem = () => ({
	id: uuidv4(),
	type: 'file',
	file: {
		multilingual: false,
		nl: undefined,
		fr: undefined
	},
	description: {
		nl: '',
		fr: ''
	}
});

export default function DocumentsInput(props: any) {
	const handleChange = (newValue: any, index: number) => props.onChange([
		...props.value.slice(0, index),
		newValue,
		...props.value.slice(index + 1)
	]);

	const deleteItem = (index: number) => props.onChange([
		...props.value.slice(0, index),
		...props.value.slice(index + 1)
	]);

	const renderItem = (item: any, index: number) => {
		const getFields = () => {
			let fields: any = [
				{
					title: 'Link tekst NL',
					name: ['description', 'nl'],
					type: 'text'
				},
				{
					title: 'Link tekst FR',
					name: ['description', 'fr'],
					type: 'text'
				},
				{
					title: 'Type',
					name: 'type',
					type: 'select',
					options: {
						choices: [
							{
								title: 'Bestand',
								value: 'file'
							},
							{
								title: 'URL',
								value: 'url'
							}
						]
					}
				}
			];

			if (item.type === 'file') {
				fields = [
					...fields,
					{
						title: 'Is bestand meertalig?',
						name: ['file', 'multilingual'],
						type: 'checkbox'
					},
					{
						title: 'Bestand NL',
						name: ['file', 'nl'],
						type: 'file',
						options: {
							...props.fileInputProps,
							accept: '.pdf'
						}
					}
				];

				if (!item.file.multilingual) {
					fields = [
						...fields,
						{
							title: 'Bestand FR',
							name: ['file', 'fr'],
							type: 'file',
							options: {
								...props.fileInputProps,
								accept: '.pdf'
							}
						},
					];
				}
			} else if (item.type === 'url') {
				fields = [
					...fields,
					{
						title: 'URL',
						name: 'url',
						type: 'text'
					}
				];
			}

			fields = [
				...fields,
				{
					title: 'Heeft dit document afwijkende start- en/of einddatums',
					name: 'hasDeviatingDates',
					type: 'checkbox'
				}
			];

			if (item.hasDeviatingDates) {
				fields = [
				...fields,
					{
						title: 'Start- en einddatum',
						name: 'startAndEnd',
						getValue: (values: CampaignDocument) => [values?.start, values?.end],
						setValue: (values: CampaignDocument, newValue: any) => ({ ...values, start: newValue[0], end: newValue[1] }),
						type: 'datetimerange',
						options: {
							translations: props.translations,
							highlightedDates: props.highlightedDates
						}
					}
				];
			}

			return fields;
		};

		return (
			<div key={item.id} className='item'>
				<DynamicForm
					fields={getFields()}
					values={item}
					onChange={(newValue: any) => handleChange(newValue, index)}
					formId={item.id}
					nested
					disabled={props.disabled}
				/>
				<Button
					onClick={() => deleteItem(index)}
					icon={<FontAwesomeIcon icon={faTrashAlt} />}
					color={'red'}
					textOnly
					className={'delete'}
					disabled={props.disabled}
				>
					Verwijderen
				</Button>
			</div>
		);
	};

	const renderItems = (items: Array<any>) => (
		<div className='items'>
			{items?.map(renderItem)}
		</div>
	);

	const renderActions = () => (
		<div className='actions'>
			<Button
				onClick={() => props.onChange([...(props?.value ?? []), { ...getNewEmptyItem() } ])}
				textOnly
				color={'green'}
				icon={<FontAwesomeIcon icon={faPlus} />}
				disabled={props.disabled}
			>
				Document toevoegen
			</Button>
		</div>
	);
	
	return (
		<div className='DocumentsInput'>
			{renderItems(props.value)}
			{renderActions()}
		</div>
	);
}
