import React, { ChangeEvent } from 'react';
import './Search.scss';

interface Props {
	onChange(e: ChangeEvent<HTMLInputElement>): void;
	value: string;
	placeholder?: string;
}

export default function Search(props: Props) {
	return (
		<input
			type='search'
			className='Search'
			onChange={props.onChange}
			value={props.value}
			placeholder={props.placeholder}
		/>
	);
}
