import React, { useEffect, useState } from 'react';
import './Account.scss';
import { useHistory, useParams } from 'react-router-dom';
import {
	DynamicForm,
	Button,
	SuppliersInput,
	BackButton
} from '@abm-international/react-components';
import { t } from './../../../../libraries/i18n';
import { createAccount } from '../../../../api/accounts/useAccount';
import usePermissions from '../../../../api/accounts/usePermissions';
import useSuppliers from '../../../../api/accounts/useSuppliers';
import { faExclamationCircle, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Language } from '../../../interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { invalidateAccounts } from '../accountsSlice';
import { fetchSuppliersIfNeeded, selectSuppliersById } from './../../../suppliers/suppliersSlice';

function getPermissionsTranslation(translationCode: string) {
	const code = translationCode.replace('permissions.', '');
	const newCode = code.split('.').join('_');
	return t(`permissions.${newCode}`);
}

export default function AccountNew() {
	const dispatch = useDispatch();
	const invalidate = () => dispatch(invalidateAccounts());
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [submitError, setSubmitError] = useState<any>(false);
	const [account, setAccount] = useState<any>({});
	const { suppliers } = useSuppliers();
	const suppliersById = useSelector(selectSuppliersById);

	useEffect(() => {
		dispatch(fetchSuppliersIfNeeded());
	}, [dispatch]);

	const checkIsValid = () => {
		let isValid = true;
		if (!account?.email) isValid = false;
		if (!account?.name) isValid = false;
		if (!account?.suppliers?.length) isValid = false;
		return isValid;
	};

	const accountFields: Array<any> = [
		{
			title: t('accounts.title_email'),
			name: 'email',
			type: 'email',
			placeholder: t('accounts.placeholder_email')
		},
		{
			title: t('accounts.title_name'),
			name: 'name',
			type: 'text',
			placeholder: t('accounts.placeholder_name')
		},
		{
			title: t('accounts.title_language'),
			name: 'language',
			type: 'select',
			options: {
				choices: [
					{
						title: t('lang.nl'),
						value: Language.NL
					},
					{
						title: t('lang.fr'),
						value: Language.FR
					},
					{
						title: t('lang.en'),
						value: Language.EN
					}
				]
			}
		},
		{
			title: t('accounts.title_suppliers'),
			name: 'suppliers',
			type: 'custom',
			placeholder: t('accounts.placeholder_suppliers'),
			options: {
				component: SuppliersInput,
				noLabel: true,
				customProps: {
					suppliers,
					suppliersById: suppliersById
				}
			}
		},
		{
			title: '',
			name: 'permissions',
			type: 'permissions',
			options: {
				noLabel: true,
				usePermissions: () => usePermissions('/manage.supplier.master.accounts/metadata'),
				t: getPermissionsTranslation,
			}
		}
	];

	const hasChanges = () => JSON.stringify(account) !== '{}';

	const submit = async () => {
		setIsSubmitting(true);
		setSubmitError(false);

		try {
			await createAccount(account);
		} catch (error) {
			setSubmitError(error);
			setIsSubmitting(false);
			return;
		}

		setIsSubmitting(false);
		invalidate();
		history.push('/accounts');
	};

	const renderSubmitError = () => (
		<div className='submit__error'>
			<FontAwesomeIcon icon={faExclamationCircle} />
			{submitError?.response?.data?.error_message}
		</div>
	);

	if (!account && id) return <div className='Account'>Loading...</div>;

	return (
		<div className='Account AccountNew'>
			<BackButton
				to={'/accounts'}
				className='back'
			>
				{t(`accounts.to_overview${hasChanges() ? '_cancel' : ''}`)}
			</BackButton>
			<h1>{t('accounts.title_new_account')}</h1>
			<DynamicForm
				fields={accountFields}
				sections={[
					{
						title: 'Gegevens',
						fields: [
							'email',
							'name',
							'language'
						]
					},
					{
						title: 'Leveranciers',
						fields: ['suppliers']
					},
					{
						title: 'Rechten',
						fields: ['permissions']
					}
				]}
				values={account}
				onChange={setAccount}
			/>
			{submitError && renderSubmitError()}
			<div className='actions'>
				<Button
					disabled={isSubmitting || !checkIsValid()}
					showSpinner={isSubmitting}
					onClick={submit}
					icon={<FontAwesomeIcon icon={faUserPlus} />}
				>
					{t('accounts.button_create')}
				</Button>
			</div>
		</div>
	)
}
