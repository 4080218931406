import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { store } from './store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { logger } from './libraries/logger/logger';
import BrowserConsoleTransport from './libraries/logger/browserConsoleTransport';
//import HttpTransport from './libraries/logger/httpTransport';

logger.init({
	transports: [
		new BrowserConsoleTransport({
			level: process.env.REACT_APP_BROWSER_LOGGING_LEVEL || 'info'
		}),
		// new HttpTransport({
		// 	endpoint: process.env.REACT_APP_LOGGING_ENDPOINT,
		// 	// Do NOT put this on SILLY; too much traffic!
		// 	level: process.env.REACT_APP_HTTP_LOGGING_LEVEL || 'warn'
		// })
	]
});

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
