import React, { useEffect, useState } from 'react';
import './Account.scss';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import {
	Button,
	SuppliersInput,
	DynamicForm,
	PortalModal,
	BackButton,
	ButtonLink
} from '@abm-international/react-components';
import { t } from './../../../../libraries/i18n';
import usePermissions from '../../../../api/accounts/usePermissions';
import useAccount, { updateAccount, deleteAccount } from './../../../../api/accounts/useAccount';
import useSuppliers from './../../../../api/accounts/useSuppliers';
import { Language } from './../../../interfaces';
import { Account } from './../../../../interfaces/accounts';
import { faBan, faCheck, faExclamationCircle, faPencilAlt, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { invalidateAccounts } from './../accountsSlice';
import { fetchSuppliersIfNeeded, selectSuppliersById } from './../../../suppliers/suppliersSlice';

function getPermissionsTranslation(translationCode: string) {
	const code = translationCode.replace('permissions.', '');
	const newCode = code.split('.').join('_');
	return t(`permissions.${newCode}`);
}

export default function AccountEdit() {
	const dispatch = useDispatch();
	const invalidate = () => dispatch(invalidateAccounts());
	const history = useHistory();
	const { id } = useParams<{id: string}>();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [submitError, setSubmitError] = useState<any>(false);
	const [newAccount, setNewAccount] = useState<any>(false);
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const match = useRouteMatch();
	const isEditing = match.path.endsWith('/edit');

	const { account, mutate } = useAccount(id);
	const { suppliers } = useSuppliers();
	const suppliersById = useSelector(selectSuppliersById);
	const [hasChanges, setHasChanges] = useState(false);

	useEffect(() => {
		dispatch(fetchSuppliersIfNeeded());
	}, [dispatch]);

	useEffect(() => {
		if (!hasChanges && !newAccount) setNewAccount(account);
	}, [hasChanges, newAccount, account])

	const checkIsValid = () => {
		let isValid = true;
		if (!newAccount?.email) isValid = false;
		if (!newAccount?.name) isValid = false;
		if (newAccount?.suppliers?.length <= 0) isValid = false;
		return isValid;
	};
		
	const accountFields: Array<any> = [
		{
			title: t('accounts.title_account'),
			name: 'account',
			type: 'text',
			disabled: true
		},
		{
			title: t('accounts.title_email'),
			name: 'email',
			type: 'email',
			placeholder: t('accounts.placeholder_email'),
			disabled: true,
		},
		{
			title: t('accounts.title_name'),
			name: 'name',
			type: 'text',
			placeholder: t('accounts.placeholder_name'),
			disabled: !isEditing,
		},
		{
			title: t('accounts.title_language'),
			name: 'language',
			type: 'select',
			disabled: !isEditing,
			options: {
				choices: [
					{
						title: t('lang.nl'),
						value: Language.NL
					},
					{
						title: t('lang.fr'),
						value: Language.FR
					},
					{
						title: t('lang.en'),
						value: Language.EN
					}
				]
			}
		},
		{
			title: t('accounts.title_suppliers'),
			name: 'suppliers',
			type: 'custom',
			placeholder: t('accounts.placeholder_suppliers'),
			disabled: !isEditing,
			options: {
				component: SuppliersInput,
				noLabel: true,
				customProps: {
					suppliers,
					suppliersById: suppliersById
				}
			}
		},
		{
			title: '',
			name: 'permissions',
			type: 'permissions',
			disabled: !isEditing,
			options: {
				noLabel: true,
				usePermissions: () => usePermissions('/manage.supplier.master.accounts/metadata'),
				t: getPermissionsTranslation
			}
		}
	];

	const submit = async () => {
		setIsSubmitting(true);
		setSubmitError(false);

		try {
			await updateAccount(id, newAccount);
		} catch (error) {
			setSubmitError(error);
			setIsSubmitting(false);
			return;
		}

		setIsSubmitting(false);
		mutate();
		invalidate();
		history.push(`/accounts/suppliers/${id}`);
	};

	const confirmDelete = async () => {
		setIsSubmitting(true);
		setSubmitError(false);

		try {
			await deleteAccount(id);
		} catch (error) {
			setSubmitError(error);
			setIsSubmitting(false);
			return;
		}

		setIsSubmitting(false);
		invalidate();
		history.push('/accounts/suppliers');
	};

	const getSections = () => {
		const sections = [
			{
				title: 'Gegevens',
				fields: [
					'account',
					'email',
					'name',
					'language'
				]
			},
			{
				title: 'Leveranciers',
				fields: ['suppliers']
			},
			{
				title: 'Rechten',
				fields: ['permissions']
			}
		];

		return sections;
	}

	const renderActions = () => {
		if (!isEditing) {
			return (
				<div className='actions'>
					<ButtonLink
						to={`/accounts/suppliers/${id}/edit`}
						icon={<FontAwesomeIcon icon={faPencilAlt} />}
					>
						{t('accounts.button_edit')}
					</ButtonLink>
				</div>
			);
		}

		return (
			<div className='actions'>
				<Button
					disabled={!checkIsValid() || isSubmitting}
					showSpinner={isSubmitting}
					onClick={submit}
					icon={<FontAwesomeIcon icon={faCheck} />}
					color='green'
					primary
				>
					{t('accounts.button_save')}
				</Button>
				<Button
					onClick={() => setShowDeleteConfirmation(true)}
					color='red'
					disabled={hasChanges}
					icon={<FontAwesomeIcon icon={faTrashAlt} />}
				>
					{t('accounts.button_delete')}
				</Button>
				<ButtonLink
					to={`/accounts/suppliers/${id}`}
					icon={<FontAwesomeIcon icon={faTimes} />}
					color='orange'
				>
					{t('accounts.button_cancel')}
				</ButtonLink>
			</div>
		);
	};

	const renderSublogins = () => (
		<div className='sublogins'>
			<h1>{t('accounts.title_sublogins')}</h1>
			<div className='items'>
				{account.subAccounts?.length <= 0 && (
					<div className='no-items'>
						{t('accounts.label_no_sublogins')}
					</div>
				)}
				{account?.subAccounts?.map((sub: Account) => (
					<div key={sub.id} className='subaccount'>
						<div className='top'>
							<div className='id'>{sub.id}</div>
							<div className='name'>{sub.name}</div>
						</div>
						<div className='email'>{sub.email}</div>
					</div>
				))}
			</div>
		</div>
	);

	const renderSubmitError = () => (
		<div className='submit__error'>
			<FontAwesomeIcon icon={faExclamationCircle} />
			{submitError?.response?.data?.error_message}
		</div>
	);

	if (!account && id) return <div className='Account'>Loading...</div>;

	return (
		<div className='Account AccountEdit'>
			<BackButton
				to={'/accounts/suppliers'}
				className='back'
			>
				{t(`accounts.to_overview${hasChanges ? '_cancel' : ''}`)}
			</BackButton>
			<h1>{t('accounts.title_manage_account')}</h1>

			{account && (
				<>
					{!account?.loginEnabled && (
						<div className='notification-blocked'>
							<FontAwesomeIcon icon={faBan} />
							{t('accounts.label_account_is_blocked')}
						</div>
					)}
					<DynamicForm
						fields={accountFields}
						sections={getSections()}
						values={newAccount}
						onChange={(data: Account) => {
							setNewAccount(data);
							setHasChanges(true);
						}}
					/>
					{submitError && renderSubmitError()}
					{renderActions()}
					{renderSublogins()}
				</>
			)}
			
			{showDeleteConfirmation &&
				<PortalModal className='sublogin__confirmation' close={() => setShowDeleteConfirmation(false)}>
					<div className='delete_modal'>
						<div className='modal__text'>
							{t('accounts.label_delete_confirmation')}
						</div>
						<div className='actions'>
							<Button
								color={'red'}
								onClick={confirmDelete}
								showSpinner={isSubmitting}
								disabled={isSubmitting}
								icon={<FontAwesomeIcon icon={faTrashAlt} />}
							>
								{t('accounts.button_confirm_delete')}
							</Button>
							<Button
								onClick={() => setShowDeleteConfirmation(false)}
								icon={<FontAwesomeIcon icon={faTimes} />}
							>
								{t('accounts.button_cancel_delete')}
							</Button>
						</div>
					</div>
				</PortalModal>
			}
		</div>
	)
}
