import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../store';
import * as API from '../../api';
import { Supplier, Suppliers } from './suppliersInterfaces';
import { selectAccounts } from '../accounts/suppliers/accountsSlice';
import { Account } from './../accounts/suppliers/accountsInterfaces';

interface SuppliersState {
	isFetching: boolean;
	error?: string;
	suppliersById: Suppliers;
}

const initialState: SuppliersState = {
	isFetching: false,
	suppliersById: {}
}

export const suppliersSlice = createSlice({
	name: 'suppliers',
	initialState,
	reducers: {
		getSuppliersStart: (state: SuppliersState): void => { state.isFetching = true },
		getSuppliersSuccess: (state: SuppliersState, { payload }: PayloadAction<Suppliers>): void => {
			state.suppliersById = payload;
			state.isFetching = false;
		},
		getSuppliersFailed: (state: SuppliersState, { payload }: PayloadAction<string>): void => {
			state.error = payload;
			state.isFetching = false;
		}
	}
});

export const {
	getSuppliersStart,
	getSuppliersSuccess,
	getSuppliersFailed
} = suppliersSlice.actions;

export const fetchSuppliersIfNeeded = (): AppThunk => async (dispatch, getState) => {
	const state = getState();
	if (selectIsFetching(state)) return;
	if (selectSuppliersById(state) !== initialState.suppliersById) return;
	dispatch(fetchSuppliers());
};

const fetchSuppliers = (): AppThunk => async dispatch => {
	try {
		dispatch(getSuppliersStart());
		const suppliersById = await API.getSuppliers();
		dispatch(getSuppliersSuccess(suppliersById));
	} catch (error) {
		dispatch(getSuppliersFailed(error.toString()));
	}
};

export const selectIsFetching = (state: RootState) => state.suppliers.isFetching;
export const selectSupplierById = (state: RootState, id: string) => state.suppliers.suppliersById[id];
export const selectSuppliersById = (state: RootState) => state.suppliers.suppliersById;
export const selectSuppliers = (state: RootState) => Object.values(state.suppliers.suppliersById);
export const selectSuppliersWithoutAccount = (state: RootState) => {
	const suppliers = selectSuppliers(state);
	const accounts = selectAccounts(state);
	const suppliersWithAccounts = accounts
		.map((a: Account) => a.suppliers)
		.flat()
		.map((s: Supplier) => s.id);
	return suppliers.filter((s: Supplier) => !suppliersWithAccounts.includes(s.id));
};

export default suppliersSlice.reducer;
