import { Language } from './../../interfaces';

export enum AccountType {
	Master = 'master',
	Sub = 'sub-account'
}

export enum AccountCategory {
	Supplier = 'supplier'
}

export enum AssociateType {
	Employee = 'employee',
	Customer = 'customer',
	Supplier = 'supplier'
}

export interface Associate {
	id: string
	code: string
	type: AssociateType
	name: string
}

export interface Accounts {
	[id: string]: Account
}

export interface Account {
	id: string
	account: string
	email: string
	username: string
	name: string
	type: AccountType
	category: AccountCategory
	language: Language
	suppliers: Array<Associate>
	isBlocked: boolean
}
