import React from "react";
import './ArchiveDetail.scss';
import { t } from '../../libraries/i18n';
import SubmissionStatusBadge from "../approvals/SubmissionStatusBadge/SubmissionStatusBadge";
import SupplierBadge from "../../components/SupplierBadge/SupplierBadge";
import {Button} from "@abm-international/react-components";
import {Link, useParams} from 'react-router-dom';
import { ReactComponent as Arrow } from './../../assets/arrow.svg';
import {SubmissionCategory, SubmissionStatus, SubmissionType} from "../../interfaces/approvals";
import { useArchiveDetails } from "../../api/archive";
import {ArchiveDetailItem} from "../../interfaces/archive";
import useMetadata from "../../api/archive/useMetadata";

function ArchiveDetail() {
	const { product: productId, supplier: supplierId } = useParams<{ product: string, supplier: string }>();

	const {
		product,
	} = useArchiveDetails(supplierId, productId);

	const {
		photoLocation,
	} = useMetadata();

	const getPhotoSrc = (file: string, type: SubmissionType) => {
		const vars = {
			supplier: supplierId,
			article: productId,
			type
		};
		return Object.entries(vars).reduce(
			(location, [key, value]) => location.replace(`{${key}}`, value),
			`${photoLocation}${file}`
		);
	}

	const renderSubmission = ({
		value,
		status,
		processed,
		registered,
		version,
		reasons,
		removed,
		category,
		type
	}: any) => {
		return (
			<div className={`submission version-${version}`} key={version}>
				{category === 'text' && <div className={'text'}>{value}</div>}
				{category === 'images' && <img src={getPhotoSrc(value, type)} className={'image'} alt={''}/>}

				<div className={'statusBlock'}>
					{removed && status !== 98 &&
						<SubmissionStatusBadge status={removed.status} since={removed.since} by={(removed.by)} />
					}
					{processed.by &&
						<SubmissionStatusBadge status={status} since={processed.since} by={(processed.by)} />
					}
					{status === SubmissionStatus.REJECTED && (
						<ul className='reasons'>
							{reasons.map((reason: {code: number, description: string}, index: number) => {
								if (`${reason.code}` === '0') {
									if (reason.description.startsWith('- ')) {
										const customReasons = reason.description.slice(2).split('\n- ');
										return customReasons.map((description, cIndex) => (
											<li key={`${index}_${cIndex}`}>
												{description}
											</li>
										));
									} else if (reason.description.startsWith('* ')) {
										const customReasons = reason.description.slice(2).split('\n* ');
										return customReasons.map((description, cIndex) => (
											<li key={`${index}_${cIndex}`}>
												{description}
											</li>
										));
									} else {
										return (
											<li key={index}>
												{reason.description}
											</li>
										);
									}

								} else {
									return (
										<li key={index}>
											{t(`approvals.reasons_${category}_${reason.code}`)}
										</li>
									);
								}

							})}
						</ul>
					)}
					<SubmissionStatusBadge status={SubmissionStatus.SUBMITTED} since={registered?.since ?? null} by={(registered?.by)} />
				</div>
			</div>
		);
	};

	const renderSubtype = (submissions: Array<ArchiveDetailItem>, subtype: string) => {
		if (submissions.length === 1) {
			return (
				<div className={`subtype subtype-${subtype}`} key={subtype}>
					{renderSubmission(submissions[0])}
				</div>
			);
		} else {
			return (
				<div className={`subtype subtype-${subtype}`} key={subtype}>
					{renderSubmission({
						...submissions[1],
						removed: {
							since: submissions[0].registered.since,
							by: submissions[0].registered.by,
							status: submissions[0].status
						}
					})}
				</div>
			);
		}
	};

	const renderType = (submissions: Array<ArchiveDetailItem>, type: SubmissionType) => {
		if(!submissions) return <span className={'noSubmissions'}>{t('archive.no_submissions')}</span>;
		submissions.sort((a, b) => +b.version - +a.version);
		if (type === SubmissionType.MOODSHOT) {
			const subtypes = submissions.reduce(sortBySubtypeReducer, {});
			// @ts-ignore
			return Object.entries(subtypes).map(([subtype, submissions]: [number, Array<ArchiveDetailItem>]) => renderSubtype(submissions, subtype));
		}
		return (
			<div className={'type'}>
				{submissions.map(renderSubmission)}
			</div>
		);
	};

	const sortBySubtypeReducer = (subtypes: any, submission: any) => {
		return {
			...subtypes,
			[submission.subtype]: [
				...subtypes[submission.subtype] ?? [],
				submission
			]
		};
	};


	// @ts-ignore
	const submissions: Record<SubmissionCategory, Record<SubmissionType, Array<ArchiveDetailItem>>> = product?.submissions
		.reduce((acc: Record<SubmissionCategory, Record<SubmissionType, Array<ArchiveDetailItem>>>, submission) => {
		return (
			{
				...acc,
				[submission.category]: {
					...(acc?.[submission.category] ?? {}),
					[submission.type]: [
							...(acc?.[submission.category]?.[submission.type] ?? []),
							submission
					]
				}
			}
		);
	}, {});



	const categories: Array<[SubmissionCategory, SubmissionType[]]> = [
		[SubmissionCategory.TEXT, [SubmissionType.NL, SubmissionType.FR, SubmissionType.EN]],
		[SubmissionCategory.IMAGE, [SubmissionType.PACKSHOT, SubmissionType.MOODSHOT]]
	];

	const renderNavigator = () => (
		<>
			<nav className={'ProductNavigator'}>
				<Link to={'/approvals/archive'}>
					<Button
						className='back'
						icon={<Arrow className={'arrow arrow-left'} />}
					>
						{t('archive.button_to_list')}
					</Button>
				</Link>
			</nav>
			<SupplierBadge supplier={{
				code: supplierId,
				name: product?.supplier.name ?? ''
			}} />
			<h1 className={'ProductHeader'}>
				<span className={'id'}>
					{productId}
				</span>
				<span className={'name'}>
					{product?.name}
				</span>
			</h1>

		</>

	);

	return (
		<div className='ArchiveDetail'>
			{product &&
			<>
				{renderNavigator()}
				{categories.map(([category , types]) => (
					types.map((type) => (
						<div className={`typeContainer type__${type}`} key={type}>
							<h2>
								<span>{t(`archive.label_change_${category}_${type}`)}</span>
							</h2>

							{renderType(submissions?.[category]?.[type], type) ?? null}
						</div>

					))
				))}

			</>
			}
		</div>
	);
}

export default ArchiveDetail;
