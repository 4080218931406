import usePermissions from './../../../api/accounts/usePermissions';
import { t } from './../../../libraries/i18n';
import CRUDHandler from './../../../components/CRUD/CRUDHandler';
import { CRUDConfig, CRUDFieldTypes } from './../../../interfaces/crud';

function getPermissionsTranslation(translationCode: string) {
	const code = translationCode.replace('permissions.', '');
	const newCode = code.split('.').join('_');
	return t(`permissions.${newCode}`);
}

const CONFIG: CRUDConfig = {
	fields: [
		{
			name: 'id',
			title: 'ID',
			type: CRUDFieldTypes.Text,
			disabled: true
		},
		{
			name: 'name',
			title: 'Naam',
			type: CRUDFieldTypes.Text
		},
		{
			name: 'username',
			title: 'Email / Gebruikersnaam',
			type: CRUDFieldTypes.Text
		},
		{
			name: 'language',
			title: 'Taal',
			type: 'select',
			options: {
				choices: [
					{
						title: 'Selecteer een taal',
						value: ''
					},
					{
						title: 'Nederlands',
						value: 'nl'
					},
					{
						title: 'Frans',
						value: 'fr'
					},
					{
						title: 'Engels',
						value: 'en'
					}
				]
			}
		},
		{
			name: 'loginEnabled',
			title: 'Kan inloggen',
			type: 'checkbox'
		},
		{
			name: 'permissions',
			title: 'Rechten',
			type: 'permissions',
			options: {
				t: getPermissionsTranslation,
				usePermissions: () => usePermissions('/manage.internal.accounts/metadata'),
				noLabel: true
			}
		}
	],
	list: {
		fetch: `/manage.internal.accounts`,
		columns: ['username', 'name']
	},
	detail: {
		location: `/manage.internal.accounts/{id}`,
		fields: [
			'id',
			'username',
			'name',
			'language',
			'loginEnabled',
			'permissions'
		],
		sections: [
			{
				title: 'Algemeen',
				fields: [
					'id',
					'username',
					'name',
					'language',
					'loginEnabled'
				]
			},
			{
				title: 'Rechten',
				fields: ['permissions']
			}
		],
		defaultNewValue: {
			permissions: []
		}
	},
	path: '/accounts/internal'
};

export default function Accounts() {
	return <CRUDHandler {...CONFIG} />;
}
