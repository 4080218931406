import useSWR, { mutate } from 'swr';
import { DEFAULT_AXIOS_CONFIG, fetcher } from '../index';
import { Account, AccountCategory, AccountType, Associate } from '../../interfaces/accounts';
import axios from 'axios';
import { Language } from './../../interfaces';
import { useMemo } from 'react';

function transformAccountInput(data: any) {
	if (!data) return null;
	return {
		...data,
		loginEnabled: data?.loginEnabled === 1,
		suppliers: data.associates?.map((a: Associate) => a?.id?.toString()),
		subAccounts: data?.['sub-accounts']
	};
}

function transformAccountOutput(account: Account) {
	return {
		email: account.email,
		username: account.email,
		name: account.name,
		type: account.type ?? AccountType.Master,
		category: account.category ?? AccountCategory.Supplier,
		language: account.language ?? Language.NL,
		associates: account.suppliers ?? [],
		loginEnabled: account.loginEnabled ? 1 : 0,
		permissions: account.permissions ?? []
	};
}

export default function useAccount(id: string) {
	const url = id && `/manage.supplier.master.accounts/${id}/master`;
	const { data, error } = useSWR(url, fetcher);

	const account = useMemo(() => transformAccountInput(data), [data]);

	return {
		account,
		isLoading: !data && !error,
		error,
		mutate: (...args: any) => mutate(url, ...args)
	};
}

export async function updateAccount(id: string, newAccount: any) {
	return axios.post(`/manage.supplier.master.accounts/${id}`, JSON.stringify(transformAccountOutput(newAccount)), DEFAULT_AXIOS_CONFIG);
}

export async function createAccount(account: Account) {
	const data = {
		...transformAccountOutput(account),
		loginEnabled: 1
	};
	return axios.post(`/manage.supplier.master.accounts/new`, JSON.stringify(data), DEFAULT_AXIOS_CONFIG);
}

export async function disableAccount(id: string, account: any) {
	const data = {
		...transformAccountOutput(account),
		loginEnabled: account.loginEnabled ? 0 : 1
	};
	return axios.post(`/manage.supplier.master.accounts/${id}`, JSON.stringify(data), DEFAULT_AXIOS_CONFIG);
}

export async function deleteAccount(id: string) {
	return axios.post(`/manage.supplier.master.accounts/${id}/delete`, null, DEFAULT_AXIOS_CONFIG);
}
