import useSWR from 'swr';
import { fetcher } from './../index';

export default function useMetadata(): { metadata: any, isLoading: boolean, error: any } {
	const { data, error } = useSWR(`/approve.supplier.commercial.data/metadata`, fetcher);

	return {
		metadata: data,
		isLoading: !error && !data,
		error
	};
}
