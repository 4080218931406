import { toast } from "react-toastify";
import { t } from "./i18n";

// TODO: Move file to react-components


const getImageDimensions = async (image: Blob): Promise<{width: number, height: number}> => {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.onload = () => {
			const width = img.naturalWidth;
			const height = img.naturalHeight;

			window.URL.revokeObjectURL( img.src );

			return resolve({ width, height });
		};
		img.onerror = () => {
			return reject();
		};

		img.src = window.URL.createObjectURL(image);
	});
};


export const validateFile = async (file: File, validations: string[]) => {
    if (!file) {
        console.error('No file selected');
        return false;
    }

    // TODO: correctly set types
    const validated = await validations.reduce(async (acc, validation) => {
        if (!await acc) {
            return acc;
        }

        const val = validation.split(':');
        switch (val[0]) {
            case 'fileType':
                // TODO: expand with array of file types
                if (file.type === val[1]) {
                    return true;
                }
                toast.error(t('commercial.upload_validation-file_type'));
                return false;

            case 'minSize':
                if ((file.size / (1024 * 1024)) >= Number(val[1])) {
                    return true;
                }
                toast.error(t('commercial.upload_validation-min_size', val[1]));
                return false;

            case 'maxSize':
                if (file.size <= Number(val[1])) {
                    return true;
                }
                toast.error(t('commercial.upload_validation-max_size', val[1]));
                return false;

            case 'minWidth':
            case 'minHeight':
            case 'maxWidth':
            case 'maxHeight':
                const { width, height } = await getImageDimensions(file);

                if (val[0] === 'minWidth') {
                    if (width >= Number(val[1])) {
                        return true;
                    }
                    toast.error(t('commercial.upload_validation-min_width', val[1]));
                    return false;
                }

                if (val[0] === 'minHeight') {
                    if (height >= Number(val[1])) {
                        return true;
                    }
                    toast.error(t('commercial.upload_validation-min_height', val[1]));
                    return false;
                }

                if (val[0] === 'maxWidth') {
                    if (width <= Number(val[1])) {
                        return true;
                    }
                    toast.error(t('commercial.upload_validation-max_width', val[1]));
                    return false;
                }

                if (val[0] === 'maxHeight') {
                    if (height <= Number(val[1])) {
                        return true;
                    }
                    toast.error(t('commercial.upload_validation-max_height', val[1]));
                    return false;
                }

                return false;
        }

        return acc;
    }, Promise.resolve(true));

    return validated;
};
