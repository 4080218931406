import React from 'react';
import './Account.scss';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import AccountEdit from './AccountEdit';
import AccountNew from './AccountNew';

export default function Account() {
	const match = useRouteMatch();

	return (
		<Switch>
			<Route exact path={`${match.path}/new`} render={() => <AccountNew />} />
			<Route exact path={`${match.path}/:id`} render={() => <AccountEdit />} />
			<Route exact path={`${match.path}/:id/edit`} render={() => <AccountEdit />} />
		</Switch>
	);
}
