import React, { useEffect, useMemo, useRef, useState } from 'react';
import useMetadata from './../../../api/approvals/useMetadata';
import './SubmissionImage.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';
import { validateFile } from '../../../libraries/validation';
import { toast } from 'react-toastify';
import { t } from '../../../libraries/i18n';
import api from '../../../libraries/api';
import { Button, Spinner } from '@abm-international/react-components';
import config from '../../../config';
import { useDispatch } from 'react-redux';
import { removeInProgress, setInProgress } from '../approvalsSlice';
import { Submission } from '../../../interfaces/approvals';


const MINIMUM_DIMENSION = 1200;
const MAXIMUM_DIMENSION = 12000; // 12000 = 100cm aan 300dpi

interface Props {
	submission: Submission;
	product: string;
	editable?: boolean;
}

export default function SubmissionImage(props: Props) {
	const { submission, product, editable } = props;
	const { metadata, isLoading, error } = useMetadata();
	const [newImage, setNewImage] = useState<string>();
	const [uploading, setUploading] = useState(false);
	const [newPath, setNewPath] = useState<string>();
	const dispatch = useDispatch()

	useEffect(() => {
		return () => {
			dispatch(removeInProgress({ product: product, item: submission.id }));
		}
	}, [])

	const inputFileRef = useRef<HTMLInputElement>(null);

	const baseUrl = useMemo(() => {
		return metadata
			?.categories
			?.images
			?.location
			?.replace('{supplier}', submission.supplier)
			.replace('{article}', product)
			.replace('{type}', submission.type)
	}, [metadata, submission, product]);

	const imageName = useMemo(() => {
		return submission.value?.split('/')[submission.value?.split('/').length - 1]
	}, [submission.value]);

	if (isLoading || error) return <img src={'/placeholder.png'} alt="placeholder" />;

	const selectNewImage = () => {
		if (inputFileRef.current) {
			inputFileRef.current.click();
		}
	}

	const resetNewImage = () => {
		setNewImage(undefined);
		if (inputFileRef.current) {
			inputFileRef.current.value = '';
		}

		dispatch(removeInProgress({ product: product, item: submission.id }));
	}

	const uploadNewImage = async () => {
		const file = inputFileRef.current?.files?.length ? inputFileRef.current.files[0] : undefined;

		if (!file) {
			return;
		}

		if (!await validateFile(file, ['minSize:2', 'fileType:image/jpeg', `minWidth:${MINIMUM_DIMENSION}`, `minHeight:${MINIMUM_DIMENSION}`, `maxWidth:${MAXIMUM_DIMENSION}`, `maxHeight:${MAXIMUM_DIMENSION}`])) {
			toast.error('Please check that the image is large enough.');
			return;
		}

		setUploading(true);

		try {
			// prepare form data
			const imageData = new FormData();
			imageData.append('image', file);
			imageData.append('url', submission.value);
			imageData.append('version', String(submission.version));

			const fetchUrl = `/api/v1/approve.supplier.commercial.data/${submission.supplier}/articles/${product}/${submission.type}/refresh-image`;

			// send data
			const res = await api.post(fetchUrl, imageData);
			if (!res.status) throw (res.error);

			// on success
			setNewPath(config.API_URL + '/' + res.path + '?' + new Date().getTime());
			resetNewImage();
			setUploading(false);
			dispatch(removeInProgress({ product: product, item: submission.id }));
			toast.success('The image was updated');
		} catch (e: any) {
			if (e.error_code === 6000) {
				toast.error(t('commercial.upload_failed-not_a_picture'));
			} else if (e.error_code === 6001) {
				toast.error(t('commercial.upload_failed-size_out_of_range'));
			} else if (e.error_code === 6002) {
				toast.error(t('commercial.upload_failed-wrong_format'));
			} else if (e.error_code === 6003) {
				toast.error(t('commercial.upload_failed-conversion_failed'));
			} else {
				toast.error(t('commercial.upload_failed'));
			}
			setUploading(false);
		}
	};

	const showFile = (files: any) => {
		const newFile = files[0]
		if (newFile) {
			setNewImage(URL.createObjectURL(newFile))
		}

		dispatch(setInProgress({ product: product, item: submission.id }));
	}

	return (
		<>
			<input
				ref={inputFileRef}
				id='file'
				name='files'
				type='file'
				style={{ display: 'none' }}
				onChange={(e) => showFile(e.target.files)}
				disabled={uploading}
				accept="image/jpg, image/jpeg, image/png"
			/>

			<div className='submission submission-image'>
				{(submission.value || newPath) && <img src={newPath || `${baseUrl}${submission.value}`} alt='' className={newImage ? 'hide' : 'show'} />}

				{newImage && <img src={newImage} alt='' />}

				{editable && !newImage && <>
					{(submission.value || newPath) &&
						<a
							className='download-btn'
							href={newPath || (baseUrl + submission.value)}
							download={imageName || 'image'}
							target='_blank'
							rel="noreferrer"
						>
							{t('approvals.download')}
							<FontAwesomeIcon icon={faDownload} />
						</a>}

					<button
						className={`upload-btn ${(!submission.value && !newPath) ? 'show' : ''}`}
						onClick={selectNewImage}
						disabled={uploading}
					>
						upload
						<FontAwesomeIcon icon={faUpload} />
					</button>
				</>}
			</div>

			{newImage && <div className='submission-confirm'>
				{!uploading && <>
					<Button onClick={resetNewImage}>{t('approvals.cancel')}</Button>
					<Button onClick={uploadNewImage}>{t('approvals.update')}</Button>
				</>}

				{uploading && <Spinner />}
			</div>}
		</>
	);
}
