import React, { useState } from 'react';
import './Archive.scss';
import { t } from './../../libraries/i18n';
import { List, Checkbox, DateRangeFilter, AutoSuggestFilter } from '@abm-international/react-components';
import { useArchive } from './../../api/archive';
import { ArchiveItem } from './../../interfaces/archive';
import SubmissionStatusBadge from './../approvals/SubmissionStatusBadge/SubmissionStatusBadge';
import SupplierBadge from './../../components/SupplierBadge/SupplierBadge';
import useWindowDimensions from './../../hooks/useWindowDimensions';
import { useHistory } from 'react-router-dom';
import { SubmissionStatus } from './../../interfaces/approvals';

interface RenderStatusFilterProps {
	value: Array<SubmissionStatus> | undefined,
	setValue(value: Array<SubmissionStatus>): () => void
}

interface RenderRegisteredFilterProps {
	value?: {
		since?: [Date, Date],
		by?: string
	},
	setValue(value: {
		since?: [Date, Date],
		by?: string
	}): () => void
}

interface RenderProcessedFilterProps {
	value?: {
		since?: [Date, Date],
		by?: string
	},
	setValue(value: {
		since?: [Date, Date],
		by?: string
	}): () => void
}

interface RenderSupplierFilterProps {
	value?: string,
	setValue(value: string): () => void
}

interface RenderArticleFilterProps {
	value?: string,
	setValue(value: string): () => void
}

interface RenderTypeFilterProps {
	value?: Array<string>,
	setValue(value: Array<string>): () => void
}

function Archive() {
	const history = useHistory();
	const [page, setPage] = useState(0);
	// TODO: reset after filter

	const {
		items,
		uniques,
		isLoading,
		error
	} = useArchive();
	const { height } = useWindowDimensions();
	const itemsPerPage = Math.floor((height - 350) / 85);

	const MONTHS = [
		'Januari',
		'Februari',
		'Maart',
		'April',
		'Mei',
		'Juni',
		'Juli',
		'Augustus',
		'September',
		'Oktober',
		'November',
		'December'
	];

	const DAYS = [
		'Maandag',
		'Dinsdag',
		'Woensdag',
		'Donderdag',
		'Vrijdag',
		'Zaterdag',
		'Zondag'
	];

	const renderArticle = (item: ArchiveItem) =>  `${item.article?.id} ${item.article?.S?.description?.nl}`;
	const renderCategory = (item: ArchiveItem) => <span className={`change category--${item.category}`}>{t(`archive.label_change_${item.category}_${item.type}`)}</span>;
	const renderStatus = (item: ArchiveItem) => <SubmissionStatusBadge status={item.status} />;
	const renderSupplier = (item: ArchiveItem) => <SupplierBadge supplier={item.supplier} />;
	const renderRegistered = (item: ArchiveItem) => <SubmissionStatusBadge since={item.registered.since} by={item.registered.by} size={'small'} />;
	const renderProcessed = (item: ArchiveItem) => <SubmissionStatusBadge since={item.processed.since} by={item.processed.by} size={'small'} />;

	const renderStatusFilter = ({value, setValue}: RenderStatusFilterProps) => {
		const safeValue = value ?? [];

		const setValues = (status: SubmissionStatus) => {
			safeValue.includes(status) ? setValue(safeValue.filter((s) => s !== status)) : setValue([...safeValue, status]);
		};

		return uniques?.statuses?.map((status) =>
			<Checkbox
				key={status}
				id={`status_${status}`}
				checked={safeValue.includes(status)}
				onToggle={() => setValues(status)}
			>
				<SubmissionStatusBadge status={+status} />
			</Checkbox>
		);
	};

	const renderTypeFilter = ({value, setValue}: RenderTypeFilterProps) => {
		const safeValue = value ?? [];

		const setValues = (type: string) => {
			safeValue.includes(type) ? setValue(safeValue.filter((t) => t !== type)): setValue([...safeValue, type]);
		};

		return uniques?.types?.map((type) =>
			<Checkbox key={type} id={`type_${type}`} checked={safeValue.includes(type)} onToggle={() => setValues(type)}>
				<span className={`change category--${type.split('_')[0]}`}>{t(`archive.label_change_${type}`)}</span>
			</Checkbox>
		);
	};

	const renderArticleFilter = ({value, setValue}: RenderArticleFilterProps) =>
		<AutoSuggestFilter
			value={value ?? ''}
			setValue={setValue}
			uniques={uniques.articles}
			placeholder={'Geef artikel of id in...'}
		/>

	const renderSupplierFilter = ({value, setValue}: RenderSupplierFilterProps) =>
		<AutoSuggestFilter
			value={value ?? ''}
			setValue={setValue}
			uniques={uniques.suppliers}
			placeholder={'Geef leverancier of id in...'}
		/>;



	const renderRegisteredFilter = ({value, setValue}: RenderRegisteredFilterProps) => {
		return <>
			<AutoSuggestFilter
				value={value?.by ?? ''}
				setValue={(by: string) => setValue({ ...value ?? { since: undefined }, by })}
				uniques={uniques.registeredUsers}
				placeholder={'Geef email in...'}
			/>
			<DateRangeFilter
				value={value?.since}
				setValue={(since: [Date, Date]) => setValue({ ...value ?? { by: undefined }, since })}
				translations={{ days: DAYS, months: MONTHS }}
			/>
		</>;
	};

	const renderProcessedFilter = ({value, setValue}: RenderProcessedFilterProps) => {
		return <>
			<AutoSuggestFilter
				value={value?.by ?? ''}
				setValue={(by: string) => setValue({ ...value ?? { since: undefined }, by })}
				uniques={uniques.processedUsers}
				placeholder={'Geef email in...'}
			/>
			<DateRangeFilter
				value={value?.since}
				setValue={(since: [Date, Date]) => setValue({ ...value ?? { by: undefined }, since })}
				translations={{ days: DAYS, months: MONTHS }}
			/>
		</>;
	};




	const testBlame = (blame: {since?: Date, by?: string}, value?: { by: string, since: [Date, Date] }) => {
		const isDateInRange = (date: Date, [begin, end]: [Date, Date]) => {
			const day = 86400000; //day in nanoseconds
			const dateTime = date?.getTime();

			if (!(begin && end && date)) return false;

			const beginTime = begin.getTime();
			const endTime = end.getTime() + day;
			return (beginTime <= dateTime) && (endTime >= dateTime);
		};

		const userSelected = blame.by?.toUpperCase().includes(value?.by?.toUpperCase() ?? '');
		const dateInRange = (value?.since && blame.since) ? isDateInRange(blame.since, value.since) : true;

		return userSelected && dateInRange;
	};

	const renderList = () => (
		<List
			gridTemplateColumns='8fr 3fr 3.5fr 8fr 3.5fr 3.5fr' // TODO: minwidths and column gap
			header={[
				{
					key: 'article',
					title: 'Artikel',
					render: renderArticle,
					filter: {
						test: ({ article }: ArchiveItem, value: string) => `${article.id} ${article.S.description.nl}`.toUpperCase().includes(value.toUpperCase()),
						component: renderArticleFilter,
						isFilterActive: (value: string) => (value !== "" && value !== undefined)
					}
				},
				{
					key: 'type',
					title: 'Type',
					render: renderCategory,
					filter: {
						test: ({ category, type }: ArchiveItem, value: Array<string>) => !value.length || value.includes(`${category}_${type}`),
						component: renderTypeFilter,
						isFilterActive: (value: Array<string>) => value?.length > 0
					}
				},
				{
					key: 'status',
					title: 'Status',
					render: renderStatus,
					filter: {
						test: ({ status }: ArchiveItem, value: Array<SubmissionStatus>) => !value.length || value.includes(status),
						component: renderStatusFilter,
						isFilterActive: (value: Array<SubmissionStatus>) => value?.length > 0
					}
				},
				{
					key: 'supplier',
					title: 'Leverancier',
					render: renderSupplier,
					filter: {
						test: ({ supplier }: ArchiveItem, value?: string) => `${supplier.code} ${supplier.name}`.toUpperCase().includes(value?.toUpperCase() ?? ''),
						component: renderSupplierFilter,
						isFilterActive: (value: string) => (value !== "" && value !== undefined)
					}
				},
				{
					key: 'registered',
					title: 'Ingevoerd',
					render: renderRegistered,
					filter: {
						test: ({ registered }: ArchiveItem, value?: { by: string, since: [Date, Date] }) => testBlame(registered, value),
						component: renderRegisteredFilter,
						isFilterActive: (value?: { by: string, since: Array<Date> }) => {
							return (value?.by !== "" && value?.by !== undefined) ||
								value?.since?.[0] ||
								value?.since?.[1];
						}
					}
				},
				{
					key: 'processed',
					title: 'Verwerkt',
					render: renderProcessed,
					filter: {
						test: ({ processed }: ArchiveItem, value?: { by: string, since: [Date, Date] }) => testBlame(processed, value),
						component: renderProcessedFilter,
						isFilterActive: (value?: { by: string, since: Array<Date> }) => {
							return (value?.by !== "" && value?.by !== undefined) ||
								value?.since?.[0] ||
								value?.since?.[1];
						}
					}
				}
			]}
			content={items}
			pagination={{
				itemsPerPage,
				page,
				setPage
			}}
			action={(e: any, item: ArchiveItem) => { // :id/archive/:category/:type
				history.push(`/approvals/archive/${item.article.id}/${item.supplier.code}`);
			}}
		/>
	);

	const renderLoadingList = () => (
		<List
			gridTemplateColumns='8fr 3fr 3.5fr 8fr 3.5fr 3.5fr'
			header={[
				{
					key: 'article',
					title: 'Artikel',
					render: renderArticle
				},
				{
					key: 'category',
					title: 'Type',
					render: renderCategory
				},
				{
					key: 'status',
					title: 'Status',
					render: renderStatus
				},
				{
					key: 'supplier',
					title: 'Leverancier',
					render: renderSupplier
				},
				{
					key: 'registered',
					title: 'Ingevoerd',
					render: renderRegistered
				},
				{
					key: 'processed',
					title: 'Verwerkt',
					render: renderProcessed
				}
			]}
			content={undefined}
			pagination={{
				itemsPerPage,
				page,
				setPage
			}}
		/>
	);

	const renderError = () => (
		<div className={'message'}>
			<span role='img' aria-label='error emoji'>💥</span>
			{t('archive.label_error_loading_overview')}
			<pre style={{backgroundColor: 'rgb(241,233,161)', fontSize: '1rem'}}>{JSON.stringify(error, null, '\t')}</pre>
		</div>
	);

	const renderNoItems = () => (
		<div className={'message'}>
			<span role='img' aria-label='celebratory emoji'>🎉</span>
			{t('archive.label_no_products_found')}
		</div>
	);

	return (
		<div className='Archive'>
			<h1>{t('archive.title')}</h1>
			{items && <h2>{t('archive.title_num_products', items?.length ?? 0)}</h2>}
			{isLoading && <h2>Laden...</h2>}
			{error && renderError()}

			{!error && !isLoading && (items?.length ?? 0) <= 0 && renderNoItems()}

			{!error && !isLoading && items && items.length > 0 && renderList()}
			{isLoading && renderLoadingList()}
		</div>
	);
}

export default Archive;
