import useSWR from 'swr';
import { fetcher } from './../index';

export default function useSuppliers() {
	const { data, error } = useSWR('/suppliers/?fetch=available', fetcher);

	return {
		suppliers: data,
		error,
		isLoading: !data && !error
	};
}
