import axios from 'axios';
import useProduct from './useProduct';
import useProducts from './useProducts';
import {
	ProposalProduct,
	SubmitApprovalInput,
	Submission,
	SubmissionStatus
} from './../../interfaces/approvals';

import { DEFAULT_AXIOS_CONFIG } from './../index';

export {
	useProduct,
	useProducts
};

function transformSubmissions(submissions: any, supplierId: string): Array<Submission> {
	return submissions?.map((s: any) => ({
		id: s.id.toString(),
		status: s.status ?? SubmissionStatus.SUBMITTED,
		since: new Date(s.registeredOn),
		value: s.value,
		type: s.type,
		subtype: s.subtype,
		version: s.version,
		category: s.category,
		supplier: supplierId,
		by: s.registeredBy,
		letCharlesDoIt: s.letCharlesDoIt
	})) ?? [];
}

export function transformProduct(data: any): ProposalProduct {
	const item = {
		...data,
		submissions: data.proposals || data.history
	};

	return {
		id: item.article.S.code,
		code: item.article.S.code,
		name: item.article.S.description.nl,
		imageUrl: item.article.images?.PS?.[0] ? `https://portal.charles.eu/resources/images/articles/1200/${item.article.images?.PS?.[0]}` : '/placeholder.png',
		supplier: {
			code: item.article.supplier.code,
			name: item.article.supplier.name
		},
		submissions: transformSubmissions(item.submissions, item.article.supplier.code)
	};
}

export async function submitApproval(input: SubmitApprovalInput)  {
	await axios.post('/approve.supplier.commercial.data', input, DEFAULT_AXIOS_CONFIG);
}
