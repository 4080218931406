import React, { useState } from 'react';
import './Approvals.scss';
import { List, Spinner } from '@abm-international/react-components';
import { useHistory } from 'react-router-dom';
import Search from './../../components/Search/Search';
import { useProducts } from './../../api/approvals';
import SupplierBadge from './../../components/SupplierBadge/SupplierBadge';
import { ProposalProduct, SubmissionCategory, SubmissionStatus } from './../../interfaces/approvals';
import { t } from '../../libraries/i18n';
import { Submission } from './../../interfaces/approvals';
import { mutateProduct } from '../../api/approvals/useProduct';

function Approvals() {
	const history = useHistory();
	const [query, setQuery] = useState('');
	const {
		products,
		isLoading,
		error
	} = useProducts();

	const approvalAction = async (e: any, product: ProposalProduct) => {
		e.stopPropagation();
		
		if (!e.defaultPrevented) {
			await mutateProduct(product.id);
			history.push(`/approvals/${product.id}`);
		}
	};

	const renderChanges = (product: ProposalProduct) => {
		const CATEGORIES = Object.values(SubmissionCategory);
		const changes = CATEGORIES.filter((category: any) => product?.submissions?.some((s: Submission) => s.category === category && s.status === SubmissionStatus.SUBMITTED));
		
		return changes.map((category: any) => <span key={category} className={`change category--${category}`}>{t(`approvals.label_change_${category}`)}</span>);
	};

	const getFilteredProducts = (products: Array<ProposalProduct>) => products?.filter(product => product.name?.toLowerCase().includes(query.toLowerCase())) ?? [];

	const renderList = () => (
		<List
			gridTemplateColumns='7rem 24rem 1fr 1fr 15rem'
			content={getFilteredProducts(products)}
			action={approvalAction}
			header={[
				{
					key: 'image',
					title: '',
					render: (product: ProposalProduct) => <img src={product.imageUrl} alt={product.name} />
				},
				{
					key: 'code-s',
					title: t('approvals.title_charles_code'),
					render: (product: ProposalProduct) => product.code
				},
				// {
				// 	key: 'supplier-code',
				// 	title: t('approvals.title_supplier_code'),
				// 	render: product => product.customerCode
				// },
				{
					key: 'name',
					title: t('approvals.title_product_name'),
					render: (product: ProposalProduct) => product.name
				},
				{
					key: 'supplier',
					title: t('approvals.title_supplier'),
					render: (product: ProposalProduct) => <SupplierBadge supplier={product.supplier} />
				},
				{
					key: 'changes',
					title: t('approvals.title_changes'),
					render: renderChanges
				}
			]}
		/>
	);

	return (
		<div className='Approvals'>
			<h1>{t('approvals.title')}</h1>
			{products && <h2>{t('approvals.title_num_products', products?.length)}</h2>}

			<div className='filters'>
				<Search value={query} placeholder={t('approvals.placeholder_search')} onChange={(e) => setQuery(e.target.value)} />
			</div>

			{isLoading && <Spinner />}

			{error && (
				<div className={'message'}>
					<span role='img' aria-label='error emoji'>💥</span>
					{t('approvals.label_error_loading_overview')}
				</div>
			)}

			{!error && !isLoading && products?.length <= 0 && (
				<div className={'message'}>
					<span role='img' aria-label='celebratory emoji'>🎉</span>
					{t('approvals.label_no_products_found')}
				</div>
			)}

			{!error && !isLoading && products?.length > 0 && renderList()}
		</div>
	);
}

export default Approvals;
