import { Language } from './index';

export interface Permission {
	code: number,
	type: string,
	group: string,
	level: number
}


export enum AccountType {
	Master = 'master',
	Sub = 'sub'
}

export enum AccountCategory {
	Supplier = 'supplier'
}

export enum AssociateType {
	Employee = 'employee',
	Customer = 'customer',
	Supplier = 'supplier'
}

export interface Associate {
	id: string
	code: string
	type: AssociateType
	name: string
}

export interface Supplier {
	id: string
	code: string
	name: string
}

export type Accounts = Record<string, Account>;

export interface Account {
	id: string
	account: string
	email: string
	username: string
	name: string
	type: AccountType
	category: AccountCategory
	language: Language
	suppliers: Array<Associate>
	isBlocked: boolean
	loginEnabled: boolean
	lastLoggedIn: Date,
	subAccounts: any
	permissions?: Array<number>
}
