import { useMemo } from "react";
import { TradeFair } from "../index.types"
import './TradeFairInfoBlock.scss';
import { Link } from "react-router-dom";

interface TradeFairInfoBlockProps extends TradeFair {
}

export const TradeFairInfoBlock = (props: TradeFairInfoBlockProps) => {
    const { id, dates } = props;

    const calendar = useMemo(() => {
        const start = dates[0]?.split('/')?.[0]?.split(' ')?.[0];
        const end = dates[dates.length -1]?.split('/')?.[1]?.split(' ')?.[0];

        return {
            start,
            end,
        }
    }, [dates]);

    return (
        <Link to={'/trade-fairs/' + id} className='TradeFairInfoBlock'>
            <h2 className="info-title">{id}</h2>
            {calendar.start} - {calendar.end}
        </Link>
    )
}
