import { TradeFairRegistrationCount } from "./TradeFairRegistrations";
import './Totals.scss'
import { useMemo } from "react";

type Props = {
  totals: TradeFairRegistrationCount;
  totalsExpected: TradeFairRegistrationCount;
  day: string | Date | unknown;
}
export const Totals = (props: Props) => {
  const { totals, totalsExpected, day } = props;

  const formatDate = useMemo(() => String(day).split("-").reverse().join("-") || String(day), [day]);
  const totalGraph = useMemo(() => Math.round(((totals.attendees || 0) + (totals.attendeesExtra || 0)) / (((totalsExpected.attendees || 0) + (totalsExpected.attendeesExtra || 0)) || 1) * 100), [totals, totalsExpected]);

  return (
    <div className="Registration-totals">
      <div className="Registration-totals__title">Registraties op {String(formatDate)}</div>
      < div className="Registration-totals__title">klanten aanwezig: <span>{totals.clients}</span>
        &nbsp;/&nbsp;
        {totalsExpected.clients} </div>

      <div className="Registration-totals__content">

        <table >
          <thead>


            <tr>
              <th>Volwassenen</th>
              <td style={{ textAlign: "right" }}>
                <span>{totals.attendees}</span>
                {!!totals.surPlusAttendees && <span style={{ color: '#868686' }}> + {totals.surPlusAttendees} extra</span>}
                &nbsp;/&nbsp;
                {totalsExpected.attendees}
              </td>
            </tr>

            <tr>
              <th>Kinderen</th>
              <td style={{ textAlign: "right" }}>
                <span>{totals.attendeesExtra}</span>
                {!!totals.surPlusAttendeesExtra && <span> + {totals.surPlusAttendeesExtra} extra</span>}
                &nbsp;/&nbsp;
                {totalsExpected.attendeesExtra}
              </td>
            </tr>

            <tr className="total">
              <th>Totaal</th>
              <td style={{ textAlign: "right" }}>
                <span>{totals.attendees + totals.attendeesExtra}</span>
                {(!!totals.surPlusAttendees || !!totals.surPlusAttendeesExtra) && <span style={{ color: '#868686' }}> + {(totals.surPlusAttendees || 0) + (totals.surPlusAttendeesExtra || 0)} extra</span>}
                &nbsp;/&nbsp;
                {(totalsExpected.attendees || 0) + (totalsExpected.attendeesExtra || 0)}
              </td>
            </tr>



          </thead>
        </table>

        <svg viewBox="0 0 35.83098862 35.83098862" width="100" height="100" xmlns="http://www.w3.org/2000/svg">
          <g><text className="circle-chart__percent" x="17.91549431" y="17.5" alignmentBaseline="central" textAnchor="middle" fontSize="6">{totalGraph}%</text></g>
          <circle className="circle-chart__background" stroke="#efefef" strokeWidth="4" fill="none" cx="17.91549431" cy="17.91549431" r="15.91549431" />
          <circle className="circle-chart__circle" stroke="#c1ad76" strokeWidth="4" strokeDasharray={`${totalGraph}, 100`} strokeLinecap={undefined} fill="none" cx="17.91549431" cy="17.91549431" r="15.91549431" />
        </svg>
      </div>


    </div>
  );
}