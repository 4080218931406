import React, { useEffect } from 'react';
import './Approval.scss';
import { t } from '../../../libraries/i18n';
import { useHistory, useParams } from 'react-router-dom';
import { useProduct } from '../../../api/approvals';
import { Spinner, Button, BackButton } from '@abm-international/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { submitApproval, selectApprovalData } from './../approvalsSlice';
import { RootState } from '../../../store';
import ApprovalSection from './../ApprovalSection/ApprovalSection';
import { SubmissionCategory, SubmissionStatus } from './../../../interfaces/approvals';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import SupplierBadge from '../../../components/SupplierBadge/SupplierBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Approval() {
	const dispatch = useDispatch();
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const { product, isLoading } = useProduct(id);
	const approvalData = useSelector((state: RootState) => selectApprovalData(state, id));

	useEffect(() => {
		if (product) {
			const numSubmitted = product.submissions.filter(s => s.status === SubmissionStatus.SUBMITTED).length;
			if (numSubmitted <= 0) history.push('/approvals');
		}
	}, [history, product]);

	const getNumHandled = () => {
		const declinedNum = approvalData.declined.filter(d => {
			return d.reasons.filter(r => r.description.trim() !== '').length > 0;
		}).length;
		return approvalData.approved.length + declinedNum;
	};

	const isPublishDisabled = () => {
		return getNumHandled() <= 0 || approvalData.inProgress?.length > 0;
	}

	const renderNumHandledHint = () => {
		const numHandled = getNumHandled();
		const totalItems = product?.submissions.filter(s => s.status === SubmissionStatus.SUBMITTED).length ?? '?'

		return (
			<div className={`items-handled ${numHandled === 0 ? 'red' : ''}`}>
				{t('approvals.label_items_handled', numHandled, totalItems)}
			</div>
		);
	};

	if (isLoading) return <Spinner />;
	if (!product) return <Spinner />;

	return (
		<div className='Approval'>
			<BackButton to={'/approvals'}>
				{t('approvals.to_overview')}
			</BackButton>

			<div className='title'>
				<h1 className='product-title'>
					<span className='product-title__code'>{product.code}</span>
					<span className='product-title__name'>{product.name}</span>
				</h1>
				<SupplierBadge supplier={product.supplier} />
			</div>

			<ApprovalSection submissions={product.submissions} category={SubmissionCategory.TEXT} />
			<ApprovalSection submissions={product.submissions} category={SubmissionCategory.IMAGE} />

			<Button
				className={'publish'}
				onClick={() => dispatch(submitApproval(approvalData))}
				disabled={isPublishDisabled()}
				icon={<FontAwesomeIcon icon={faCloudUploadAlt} />}
				primary
			>
				{t('approvals.publish')}
			</Button>
			{renderNumHandledHint()}
			{approvalData.inProgress?.length > 0 && <span className='error-in-progress'>
				{t('approvals.error_in_progress')}
			</span>}
		</div>
	);
}

export default Approval;
