import React, { useEffect, useMemo, useState } from 'react';
import './Campaign.scss';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { t } from '../../../libraries/i18n';
import useCampaign, { deleteCampaign, saveCampaign } from '../../../api/marketing/useCampaign';
import {
	DynamicForm,
	Button,
	PortalModal,
	BackButton,
	ButtonLink
} from '@abm-international/react-components';
import DocumentsInput from './../DocumentsInput/DocumentsInput';
import ArticlesInput from './../ArticlesInput/ArticlesInput';
import BannersInput from './../BannersInput/BannersInput';
import { faCheck, faInfoCircle, faPencilAlt, faTimes, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import { ICampaign } from './../../../interfaces/marketing/campaigns';
import { v4 as uuidv4 } from 'uuid';
import { mutateCampaigns } from '../../../api/marketing/useCampaigns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MONTHS = [
	'Januari',
	'Februari',
	'Maart',
	'April',
	'Mei',
	'Juni',
	'Juli',
	'Augustus',
	'September',
	'Oktober',
	'November',
	'December'
];

const DAYS = [
	'Maandag',
	'Dinsdag',
	'Woensdag',
	'Donderdag',
	'Vrijdag',
	'Zaterdag',
	'Zondag'
];

const getFileInputProps = (id?: string) => ({
	type: 'file',
	baseUrl: `${process.env.REACT_APP_PORTAL_URL}/api/sales/campaigns/${id}/file`,
	translate: (key: string) => t(`common.${key}`),
	prefix: `${process.env.REACT_APP_PORTAL_URL}/resources/campaigns`
});

const getFields = (campaign: ICampaign | undefined, highlightedDates: any, fileInputProps: any) => {
	const fields = [
		{
			title: 'Naam NL',
			name: ['name', 'nl'],
			type: 'text'
		},
		{
			title: 'Naam FR',
			name: ['name', 'fr'],
			type: 'text'
		},
		{
			title: 'Start- en einddatum',
			name: 'startAndEnd',
			type: 'datetimerange',
			getValue: (values: ICampaign) => [values.start, values.end],
			setValue: (values: ICampaign, newValue: any) => ({ ...values, start: newValue[0], end: newValue[1] }),
			options: {
				translations: { months: MONTHS, days: DAYS }
			}
		},
		{
			title: 'Actief',
			name: 'active',
			type: 'checkbox'
		},
		{
			title: 'Heeft deze campagne artikels?',
			name: 'hasArticles',
			type: 'checkbox'
		},
		{
			title: 'Hebben deze artikels een badge?',
			name: 'hasBadge',
			type: 'checkbox'
		},
		{
			title: 'Tekst NL',
			name: ['articles', 'badge', 'text', 'nl'],
			type: 'text'
		},
		{
			title: 'Tekst FR',
			name: ['articles', 'badge', 'text', 'fr'],
			type: 'text'
		},
		{
			title: 'Kleur achtergrond',
			name: ['articles', 'badge', 'color', 'background'],
			type: 'color'
		},
		{
			title: 'Kleur tekst',
			name: ['articles', 'badge', 'color', 'text'],
			type: 'color',
			options: {
				suggestions: [
					'#FFFFFF',
					'#000000'
				]
			}
		},
		{
			title: 'Heeft de badge een afbeelding?',
			name: 'badgeHasImage',
			type: 'checkbox'
		},
		{
			title: 'Artikels ophalen via datawarehouse?',
			name: ['articles', 'dmz'],
			type: 'checkbox'
		},
		{
			title: 'Artikels',
			name: ['articles', 'ids'],
			type: 'custom',
			options: {
				component: ArticlesInput
			}
		},
		{
			title: 'Is de afbeelding meertalig?',
			name: ['articles', 'badge', 'image', 'multilingual'],
			type: 'checkbox'
		},
		{
			title: 'Afbeelding NL',
			name: ['articles', 'badge', 'image', 'nl'],
			type: 'file',
			options: {
				...fileInputProps,
				accept: '.jpg, .jpeg, .png, .gif',
				preview: (value: string) => <img src={value} className='file-input--preview' alt='Uw geupload bestand' />
			}
		},
		{
			title: 'Afbeelding FR',
			name: ['articles', 'badge', 'image', 'fr'],
			type: 'file',
			options: {
				...fileInputProps,
				accept: '.jpg, .jpeg, .png, .gif',
				preview: (value: string) => <img src={value} className='file-input--preview' alt='Uw geupload bestand' />
			}
		},
		{
			title: 'Hebben deze artikels een filter?',
			name: 'hasFilter',
			type: 'checkbox'
		},
		{
			title: 'Tekst NL',
			name: ['articles', 'filter', 'text', 'nl'],
			type: 'text'
		},
		{
			title: 'Tekst FR',
			name: ['articles', 'filter', 'text', 'fr'],
			type: 'text'
		},
		{
			title: 'Hebben de badge en filter een andere start- en/of einddatum?',
			name: 'articlesHasDeviatingDates',
			type: 'checkbox'
		},
		{
			title: 'Start- en einddatum',
			name: ['articles', 'startAndEnd'],
			type: 'datetimerange',
			getValue: (values: ICampaign) => [values.articles?.start, values.articles?.end],
			setValue: (values: ICampaign, newValue: any) => ({ ...values, articles: { ...values.articles, start: newValue[0], end: newValue[1] } }),
			options: {
				translations: { months: MONTHS, days: DAYS },
				highlightedDates
			}
		},
		{
			title: 'Heeft deze campagne documenten?',
			name: 'hasDocuments',
			type: 'checkbox'
		},
		{
			title: 'Is de cover meertalig?',
			name: ['documents', 'cover', 'multilingual'],
			type: 'checkbox'
		},
		{
			title: 'Cover NL',
			name: ['documents', 'cover', 'nl'],
			type: 'file',
			options: {
				...fileInputProps,
				accept: '.jpg, .jpeg, .png, .gif',
				preview: (value: string) => <img src={value} className='file-input--preview' alt='Uw geupload bestand' />
			}
		},
		{
			title: 'Cover FR',
			name: ['documents', 'cover', 'fr'],
			type: 'file',
			options: {
				...fileInputProps,
				accept: '.jpg, .jpeg, .png, .gif',
				preview: (value: string) => <img src={value} className='file-input--preview' alt='Uw geupload bestand' />
			}
		},
		{
			title: 'Gewicht',
			name: ['documents', 'cover', 'weight'],
			type: 'number'
		},
		{
			title: 'Tekst onder campagne naam NL',
			name: ['documents', 'copy', 'nl'],
			type: 'textarea'
		},
		{
			title: 'Tekst onder campagne naam FR',
			name: ['documents', 'copy', 'fr'],
			type: 'textarea'
		},
		{
			title: 'Documenten',
			name: ['documents', 'items'],
			type: 'custom',
			options: {
				component: DocumentsInput,
				customProps: {
					highlightedDates,
					translations: { months: MONTHS, days: DAYS },
					fileInputProps
				}
			}
		},
		{
			title: 'Heeft deze campagne banners?',
			name: 'hasBanners',
			type: 'checkbox'
		},
		{
			title: 'Banners',
			name: 'banners',
			type: 'custom',
			options: {
				component: BannersInput,
				customProps: {
					documents: campaign?.documents?.items,
					highlightedDates,
					translations: { months: MONTHS, days: DAYS },
					fileInputProps
				}
			}
		}
	];

	return fields;
};

const getSections = (campaign: ICampaign | undefined) => {
	const getArticlesFields = () => {
		let fields: any = ['hasArticles'];

		if (!campaign?.hasArticles) return fields;

		fields = [
			...fields,
			'articles_dmz'
		];

		if (!campaign?.articles?.dmz) {
			fields = [
				...fields,
				'articles_ids'
			];
		}

		fields = [
			...fields,
			'hasBadge'
		];

		if (campaign?.hasBadge) {
			fields = [
				...fields,
				['articles_badge_text_nl', 'articles_badge_text_fr'],
				['articles_badge_color_background', 'articles_badge_color_text'],
				'badgeHasImage'
			];

			if (campaign?.badgeHasImage) {
				fields = [
					...fields,
					'articles_badge_image_multilingual'
				];

				if (campaign?.articles?.badge?.image?.multilingual) {
					fields = [
						...fields,
						'articles_badge_image_nl'
					];
				} else {
					fields = [
						...fields,
						['articles_badge_image_nl', 'articles_badge_image_fr']
					];
				}
			}
		}

		fields = [...fields, 'hasFilter'];

		if (campaign?.hasFilter) {
			fields = [
				...fields,
				['articles_filter_text_nl', 'articles_filter_text_fr']
			];
		}

		fields = [...fields, 'articlesHasDeviatingDates'];

		if ((campaign?.hasBadge || campaign?.hasFilter) && campaign?.articlesHasDeviatingDates) {
			fields = [
				...fields,
				'articles_startAndEnd'
			];
		}

		return fields;
	};

	const getDocumentsFields = () => {
		let fields: any = ['hasDocuments'];

		if (!campaign?.hasDocuments) return fields;

		fields = [
			...fields,
			'documents_cover_multilingual',
		];

		if (!campaign?.documents?.cover?.multilingual) {
			fields = [
				...fields,
				'documents_cover_nl',
				'documents_cover_fr',
			];
		} else {
			fields = [
				...fields,
				'documents_cover_nl'
			];
		}

		fields = [
			...fields,
			'documents_cover_weight',
			'documents_copy_nl',
			'documents_copy_fr',
			'documents_items'
		];

		return fields;
	};

	const getBannersFields = () => {
		let fields = ['hasBanners'];

		if (!campaign?.hasBanners) return fields;

		fields = [
			...fields,
			'banners'
		];

		return fields;
	};

	const sections = [
		{
			title: 'Algemeen',
			fields: [
				['name_nl', 'name_fr'],
				'startAndEnd',
				'active'
			]
		},
		{
			title: 'Artikels',
			fields: getArticlesFields()
		},
		{
			title: 'Documenten',
			fields: getDocumentsFields()
		},
		{
			title: 'Banners',
			fields: getBannersFields()
		}
	];

	return sections;
};

const getHighlightedDates = (a: Date | undefined, b: Date | undefined) => {	
	if (!a || !b) return [];

	const start = new Date(a);
	const end = new Date(b);

	const inbetweeners = [];
	const currentDate = new Date(start.getTime());
	currentDate.setDate(currentDate.getDate() + 1);

	for (let dt = new Date(start); dt.getTime() <= end.getTime(); dt.setDate(dt.getDate() + 1)) {
		inbetweeners.push(new Date(dt));
	}

	const dates = [
		{
			className: 'start-highlight',
			dates: [start]
		},
		{
			className: 'end-highlight',
			dates: [end]
		}
		,
		{
			className: 'inbetweeners-highlight',
			dates: [...inbetweeners]
		}
	];

	return dates;
};

export default function Campaign() {
	const { path } = useRouteMatch();
	const history = useHistory();
	const isNew = path.endsWith('/new');
	const isEditing = isNew || path.endsWith('/edit');
	const { id } = useParams<{ id: string }>();
	const { campaign, mutate } = useCampaign(id ?? null);
	const canEdit = useMemo(() => !campaign?.legacy ?? false, [campaign]);
	const [hasChanges, setHasChanges] = useState(false);
	const [newCampaign, setNewCampaign] = useState<ICampaign>();
	const [saveResult, setSaveResult] = useState<any>();
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		if ((!hasChanges && !newCampaign) || !isEditing) setNewCampaign(campaign);
	}, [campaign, hasChanges, newCampaign, isEditing]);

	// New campaigns need an id asap,
	// because we need the id for file uploads
	useEffect(() => {
		if (isNew && newCampaign && newCampaign.id === 'new') setNewCampaign({ ...newCampaign, id: uuidv4() });
	}, [isNew, newCampaign]);

	const highlightedDates = useMemo(() => getHighlightedDates(newCampaign?.start, newCampaign?.end), [newCampaign?.start, newCampaign?.end]);

	const fileInputProps = useMemo(() => getFileInputProps(newCampaign?.id), [newCampaign?.id])

	const fields = useMemo(() => getFields(newCampaign, highlightedDates, fileInputProps), [fileInputProps, highlightedDates, newCampaign])

	const sections = useMemo(() => getSections(newCampaign), [newCampaign]);

	const save = async () => {
		if (!newCampaign) return null;

		setSaveResult(undefined);
		setIsSubmitting(true);
		const result = await saveCampaign(newCampaign);
		setSaveResult(result);
		setIsSubmitting(false);

		if (result?.success) {
			mutateCampaigns();
			setTimeout(() => {
				mutate();
				history.push('/marketing/campaigns');
			}, 1000);
		}
	};

	const confirmDelete = async () => {
		if (!id) return null;

		setIsSubmitting(true);
		const result = await deleteCampaign(id);
		setIsSubmitting(false);

		if (result?.success) {
			mutateCampaigns();
			history.push('/marketing/campaigns');
		}
	};

	const renderForm = () => {
		if (!newCampaign) return null;

		return (
			<DynamicForm
				fields={fields}
				sections={sections}
				values={newCampaign}
				onChange={(c: ICampaign) => {
					setHasChanges(true);
					setNewCampaign(c);
				}}
				disabled={!isEditing}
			/>
		);
	};

	const renderActions = () => (
		<div className='actions'>
			{isEditing &&
				<Button
					color={'green'}
					icon={<FontAwesomeIcon icon={faCheck} />}
					onClick={save}
					showSpinner={isSubmitting}
					disabled={isSubmitting || saveResult?.success}
					primary
				>
					Opslaan
				</Button>
			}

			{!isEditing && canEdit &&
				<ButtonLink
					to={`/marketing/campaigns/${id}/edit`}
					className='back'
					icon={<FontAwesomeIcon icon={faPencilAlt} />}
					primary
				>
					Aanpassen
				</ButtonLink>
			}

			{!isNew && canEdit &&
				<Button
					color={'red'}
					icon={<FontAwesomeIcon icon={faTrashAlt} />}
					onClick={() => setShowDeleteConfirmation(true)}
					disabled={isSubmitting || saveResult?.success}
				>
					Campagne verwijderen
				</Button>
			}

			{!isNew && isEditing &&
				<Button
					color={'orange'}
					icon={<FontAwesomeIcon icon={faTimes} />}
					onClick={() => history.push('/marketing/campaigns')}
					disabled={isSubmitting || saveResult?.success}
				>
					Wijzigingen annuleren
				</Button>
			}

			{isNew &&
				<Button
					color={'orange'}
					icon={<FontAwesomeIcon icon={faTimes} />}
					onClick={() => history.push('/marketing/campaigns')}
					disabled={isSubmitting || saveResult?.success}
				>
					Nieuwe campagne annuleren
				</Button>
			}
		</div>
	);

	const renderTitle = () => {
		if (isNew) return <h1>{t('marketing.title_campaign_new')}</h1>
		if (isEditing) return <h1>{t('marketing.title_campaign_edit')}</h1>

		return <h1>{t('marketing.title_campaign_view')}</h1>
	};

	const renderSaveResult = () => {
		if (saveResult === undefined) return null;

		if (saveResult instanceof Error) {
			return (
				<div className='save-message error'>
					Er is iets fout gegaan bij het opslaan van de campagne: "{saveResult.message}"
				</div>
			);
		}

		if (!saveResult?.success) {
			return (
				<div className='save-message error'>
					Er is iets fout gegaan bij het opslaan van de campagne: "{saveResult.error}"
				</div>
			);
		}

		if (saveResult?.success) {
			return (
				<div className='save-message success'>
					Campagne opgeslaan
				</div>
			);
		}
		
		return null;
	};

	const renderDeleteConfirmation = () => showDeleteConfirmation && (
		<PortalModal className='delete__confirmation' close={() => setShowDeleteConfirmation(false)}>
			<div className='delete_modal'>
				<div className='modal__text'>
					{t('marketing.label_delete_confirmation_campaign')}
				</div>
				<div className='actions'>
					<Button
						className={'red'}
						onClick={confirmDelete}
						showSpinner={isSubmitting}
						disabled={isSubmitting}
						icon={<FontAwesomeIcon icon={faTrashAlt} />}
					>
						{t('marketing.button_confirm_delete_campaign')}
					</Button>
					<Button
						onClick={() => setShowDeleteConfirmation(false)}
						icon={<FontAwesomeIcon icon={faTimes} />}
					>
						{t('marketing.button_cancel_delete_campaign')}
					</Button>
				</div>
			</div>
		</PortalModal>
	);

	const renderLegacyCampaignWarning = () => campaign?.legacy && (
		<div className='campaign-legacy'>
			<FontAwesomeIcon icon={faInfoCircle} />
			Dit is een oude campagne en kan niet aangepast worden.
		</div>
	);

	return (
		<div className='Campaign'>
			<BackButton
				to={'/marketing/campaigns'}
			>
				{t(`marketing.to_overview${isEditing ? '_cancel' : ''}`)}
			</BackButton>
			{renderTitle()}
			{renderForm()}
			{renderSaveResult()}
			{renderActions()}
			{renderDeleteConfirmation()}
			{renderLegacyCampaignWarning()}
		</div>
	);
}
