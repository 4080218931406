import useSWR, { mutate } from 'swr';
import { fetcher } from './../index';
import { transformProduct } from './index';

import {
	ProposalProduct
} from '../../interfaces/approvals';

const getProductFetchKey = (id: string) => `/approve.supplier.commercial.data/articles/${id}`;

export const mutateProduct = (id: string) => mutate(getProductFetchKey(id), null, true);

export default function useProduct(id: string): { product: ProposalProduct | undefined, isLoading: boolean, error: any } {
	const { data, error } = useSWR(getProductFetchKey(id), fetcher);

	return {
		product: data?.[id] ? transformProduct(data[id]) : undefined,
		isLoading: !error && !data,
		error
	};
}
