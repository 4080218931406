import React from 'react';
import './SupplierBadge.scss';

interface Props {
	supplier: {
		code: string
		name: string
	}
}

export default function SupplierBadge(props: Props) {
	return (
		<div className='SupplierBadge'>
			<span className='supplier__code'>{props.supplier?.code}</span>
			<span className='supplier__name'>{props.supplier?.name}</span>
		</div>
	);
}
