import React from 'react';
import './SubmissionItem.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import ApprovalActions from './../ApprovalActions/ApprovalActions';
import {
	setIsApproved,
	setReasons,
	selectItemDeclinedReasons,
	selectIsItemApproved
} from './../../approvals/approvalsSlice';
import { RootState } from '../../../store';
import { SubmissionStatus } from '../../../interfaces/approvals';
import { Submission } from './../../../interfaces/approvals';
import SubmissionStatusBadge from './../SubmissionStatusBadge/SubmissionStatusBadge';
import { t } from '../../../libraries/i18n';

interface Props {
	submission: Submission
	renderSubmission(submission: Submission): any
	REASONS: Array<{ id: string }>
	textChangesbyInternal?: string
}

export default function SubmissionItem(props: Props) {
	const dispatch = useDispatch();
	const {
		submission,
		renderSubmission,
		REASONS,
		textChangesbyInternal,
	} = props;

	const { id } = useParams<{ id: string }>();
	const isApproved = useSelector((state: RootState) => selectIsItemApproved(state, `${id}.${submission.id}`));
	const reasons = useSelector((state: RootState) => selectItemDeclinedReasons(state, `${id}.${submission.id}`));

	const handleSetIsApproved = (isApproved: boolean | undefined) => {
		dispatch(setIsApproved({ value: isApproved, product: id, item: `${id}.${submission.id}`, approvedWithChanges: textChangesbyInternal }));
	}

	const handleSetReasons = (reasons: any) => {
		if (Object.values(reasons).some(value => value) && isApproved !== false) handleSetIsApproved(false);
		if (Object.values(reasons).every(value => !value) && isApproved !== undefined) handleSetIsApproved(undefined);

		dispatch(setReasons({ product: id, item: `${id}.${submission.id}`, value: reasons }));
	};

	return (
		<div className='SubmissionItem'>
			<div>
				{renderSubmission(submission)}
			</div>
			<div className='info'>
				<SubmissionStatusBadge status={submission.status} since={submission.since} by={submission.by} />

				{(submission.letCharlesDoIt) && <div className='SubmissionStatusBadge'>
					{submission.status >= 99 && <span className='version__status TRANSLATE'>{t('approvals.translated_by_charles')}</span>}
					{submission.status < 99 && <span className='version__status TRANSLATE'>{t('approvals.to-be-translated_by_charles')}</span>}
				</div>}

				{submission.status === SubmissionStatus.SUBMITTED &&
					<ApprovalActions
						isApproved={isApproved}
						setIsApproved={handleSetIsApproved}
						reasons={reasons}
						setReasons={handleSetReasons}
						REASONS={REASONS}
						category={submission.category}
						submissionId={submission.id}
					/>
				}
			</div>
		</div>
	);
}
