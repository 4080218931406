import useSWR from 'swr';
import { ProposalProduct } from './../../interfaces/approvals';
import { fetcher } from './../index';
import { transformProduct } from './index';

export default function useProducts(): { products: Array<ProposalProduct>, isLoading: boolean, error: any } {
	const { data, error } = useSWR(`/approve.supplier.commercial.data/overview`, fetcher);

	return {
		products: !data ? [] : Object.entries(data)?.map(([id, product]) => transformProduct({ ...(product as object), id })),
		isLoading: !data && !error,
		error
	};
}
