import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import './Marketing.scss';
import Campaigns from './Campaigns/Campaigns';
import Campaign from './Campaign/Campaign';

export default function Marketing() {
	const { path } = useRouteMatch();

	return (
		<Switch>
			<Route exact path={path} render={() => <Redirect to={`${path}/campaigns`} />} />
			<Route exact path={`${path}/campaigns`} component={Campaigns} />
			<Route exact path={`${path}/campaigns/new`} component={Campaign} />
			<Route exact path={`${path}/campaigns/:id`} component={Campaign} />
			<Route exact path={`${path}/campaigns/:id/edit`} component={Campaign} />
		</Switch>
	);
}
