import { Route, Switch } from "react-router-dom"
import { TradeFairsOverview } from "./TradeFairsOverview"
import { TradeFairRegistrations } from "./TradeFairRegistrations"

export const TradeFairsModule = () => {
    return (
        <Switch>
            <Route exact path={'/trade-fairs'} component={TradeFairsOverview} />
            <Route exact path={'/trade-fairs/:tradeFairId'} component={TradeFairRegistrations} />
        </Switch>
    )
}
