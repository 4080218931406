import useArchive from './useArchive';
import useArchiveDetails from './useArchiveDetails';
import {
	ArchiveDetailItem,
	ArchiveDetailProduct,
	ArchiveItem,
	ArchiveResponse, Article
} from '../../interfaces/archive';
import { SubmissionStatus } from '../../interfaces/approvals';

export {
	useArchive,
	useArchiveDetails
};

export const transformItems = ({ archive, suppliers, articles }: ArchiveResponse): Array<ArchiveItem> => {

	return archive.map(item => ({
		id: item.id,
		article: { id: item.article, ...articles[item.article]} as Article,
		supplier: suppliers[item.supplier],
		category: item.category,
		type: item.type,
		subtype: item.subtype,
		version: item.version,
		status: item.status,
		processed: {
			since: item.processedOn ? new Date(item.processedOn) : undefined,
			by: item.processedBy
		},
		registered: {
			since: new Date(item.registeredOn),
			by: item.registeredBy
		}
	})).sort((a: ArchiveItem, b: ArchiveItem) => {
		if (!a.processed.since) return -1;
		if (!b.processed.since) return 1;
		return b.processed.since.getTime() - a.processed.since.getTime();
	});
};

export function transformArchiveDetails(data: any): ArchiveDetailProduct {
	const item = {
		...data,
		submissions: data?.commercial?.data?.history
	};

	return {
		id: item.article.S.code,
		code: item.article.S.code,
		name: item.article.S.description.nl,
		imageUrl: item.article.images?.PS?.[0] ? `https://portal.charles.eu/resources/images/articles/1200/${item.article.images?.PS?.[0]}` : '/placeholder.png',
		supplier: {
			code: item.supplier.code,
			name: item.supplier.name
		},
		submissions: transformSubmissions(item.submissions, item.supplier.code)
	};
}

function transformSubmissions(submissions: any, supplierId: string): Array<ArchiveDetailItem> {
	return submissions?.map((s: any) => ({
		id: `${s.category}_${s.type}_${s.subtype}_${s.version}`,
		status: s.status ?? SubmissionStatus.SUBMITTED,
		registered: {
			since: new Date(s.registeredOn),
			by: s.registeredBy
		},
		processed: {
			since: new Date(s.processedOn),
			by: s.processedBy
		},
		value: s.value,
		reasons: s.reasons,
		type: s.type,
		category: s.category,
		subtype: s.subtype,
		version: s.version,
		supplier: supplierId,
		letCharlesDoIt: s.letCharlesDoIt
	})) ?? [];
}
