import axios from 'axios';
import {
	User,
	UserRoute,
	UserPermission
} from './modules/user/userInterfaces';
import {
	Account,
	Accounts,
	AccountType,
	AssociateType,
	AccountCategory
} from './modules/accounts/suppliers/accountsInterfaces';
import {
	Supplier,
	Suppliers
} from './modules/suppliers/suppliersInterfaces';
import {
	Language
} from './modules/interfaces';

const API_ENDPOINT = `${process.env.REACT_APP_PORTAL_URL}/api`;
const DEFAULT_AXIOS_CONFIG = {
	withCredentials: true
};

interface UserResult {
	username: string
	routes: Array<UserRoute>
	roles: Array<UserPermission>
	id: number,
	language: string,
	[propName: string]: any
}

const transformUser = (data: UserResult): User => ({
	username: data.username,
	routes: data.routes,
	permissions: data.roles,
	locale: data.language,
	id: data.id?.toString()
});

export const getUser = async (): Promise<User> => {
	const url = `${API_ENDPOINT}/me`;
	const config = { ...DEFAULT_AXIOS_CONFIG };

	try {
		const { data } = await axios.get<UserResult>(url, config);
		const userData = transformUser(data);
		return userData;
	} catch (error) {
		throw error;
	}
};

interface AccountResult {
	id: string
	account: string
	email: string
	username: string
	name: string
	type: AccountType
	category: AccountCategory
	language: Language
	associates: Array<{
		company: string
		id: string
		code: string
		type: AssociateType
		name: string
	}>
	loginEnabled: number
	lastLoggedIn: string
}

const transformAccounts = (data: Array<AccountResult>): Accounts => {
	let accounts: Accounts = {};
	Object.entries(data).forEach(([index, account]) => {
		accounts[account.id] = {
			id: account.id.toString(),
			account: account.account.toString(),
			email: account.email,
			name: account.name,
			username: account.username,
			type: account.type,
			category: account.category,
			language: account.language,
			suppliers: account.associates,
			isBlocked: account.loginEnabled === 0 
		};
	});
	return accounts;
};

export const getAccounts = async (): Promise<Accounts> => {
	const url = `${API_ENDPOINT}/manage.supplier.master.accounts`;
	
	try {
		const { data } = await axios.get<Array<AccountResult>>(url, DEFAULT_AXIOS_CONFIG);
		const accountsData = transformAccounts(data);
		return accountsData;
	} catch (error) {
		throw error;		
	}
};

interface AccountUpdateInput {
	username: string
	name: string
	email: string
	type: AccountType
	category: AccountCategory
	language: Language
	associates: Array<string>
	loginEnabled: boolean
}

const getAccountUpdateInput = (account: Account): AccountUpdateInput => ({
	username: account.username ?? account.email,
	name: account.name,
	email: account.email,
	type: account.type ?? AccountType.Master,
	category: account.category ?? AccountCategory.Supplier,
	language: account.language ?? Language.NL,
	associates: account.suppliers.map((supplier: Supplier) => supplier.id),
	loginEnabled: !account.isBlocked
});

export const updateAccount = async (account: Account): Promise<any> => {
	const url = `${API_ENDPOINT}/manage.supplier.master.accounts/${account.id}`;

	try {
		const accountUpdateInput = getAccountUpdateInput(account);
		const { data } = await axios.post(url, accountUpdateInput, DEFAULT_AXIOS_CONFIG);
		return data;
	} catch (error) {
		throw error;
	}
};

export const deleteAccount = async (account: Account): Promise<any> => {
	const url = `${API_ENDPOINT}/manage.supplier.master.accounts/${account.id}/delete`;

	try {
		const accountUpdateInput = getAccountUpdateInput(account);
		const { data } = await axios.post(url, accountUpdateInput, DEFAULT_AXIOS_CONFIG);
		return data;
	} catch (error) {
		throw error;
	}
};

export const createAccount = async (account: Account): Promise<any> => {
	const url = `${API_ENDPOINT}/manage.supplier.master.accounts/new`;

	try {
		const accountUpdateInput = getAccountUpdateInput(account);
		const { data } = await axios.post(url, accountUpdateInput, DEFAULT_AXIOS_CONFIG);
		return data;
	} catch (error) {
		throw error;
	}
};

interface SupplierResult {
	id: number
	code: string
	name: string
}

const transformSuppliers = (data: Array<SupplierResult>): Suppliers => {
	const suppliers: Record<string, Supplier> = {};
	data.forEach(s => suppliers[s.id.toString()] = { ...s, id: s.id.toString() });
	return suppliers;
};

export const getSuppliers = async (): Promise<Suppliers> => {
	const url = `${API_ENDPOINT}/suppliers/?fetch=active`;
	
	try {
		const { data } = await axios.get<Array<SupplierResult>>(url, DEFAULT_AXIOS_CONFIG);
		const suppliersData = transformSuppliers(data);
		return suppliersData;
	} catch (error) {
		throw error;
	}
};
