import {
	DynamicForm,
	CharlesTitle,
	Button,
	PortalModal,
	ButtonLink,
	BackButton
} from '@abm-international/react-components';
import { CRUDDetailConfig, CRUDField } from './../../interfaces/crud';
import useSWR from 'swr';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { faCheck, faPencilAlt, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import './CRUDDetail.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DEFAULT_AXIOS_CONFIG, fetcher } from './../../api/index';
import axios from 'axios';

const getURLWithParams = (url: string | null, params: Record<string, string>): string | null => {
	if (!url) return null;

	const urlWithParams = (Object.keys(params)).reduce((newUrl: any, param: any) => {
		const p = `{${param}}`;
		const newerUrl = newUrl.replace(new RegExp(p, 'gi'), params[param]);
		return newerUrl;
	}, url);

	return urlWithParams;
};

const getFields = (data: Array<string>, fields: Array<CRUDField>): any =>
	data.map((col: string) =>  fields.find((field: CRUDField) => field.name === col));

const deleteItem = async (id: string) => {
	try {
		const { data } = await axios.post(`/manage.internal.accounts/${id}/delete`, {}, DEFAULT_AXIOS_CONFIG);

		return {
			success: true,
			data
		};
	} catch (error) {
		return {
			success: false,
			error
		};
	}
};

const saveItem = async (id: string, item: any) => {
	try {
		const { data } = await axios.post(`/manage.internal.accounts/${id}`, item, DEFAULT_AXIOS_CONFIG);

		return {
			success: true,
			data
		};
	} catch (error) {
		return {
			success: false,
			error
		};
	}
};

const useDetailItem = (url: string | null, params: Record<string, string>) => {
	const urlWithParams = getURLWithParams(url, params);
	const { data, error, mutate } = useSWR(urlWithParams, fetcher);

	return {
		data,
		error,
		mutate
	};
};

interface Props {
	mode: string,
	fields: Array<CRUDField>,
	path: string,
	config: CRUDDetailConfig,
	children?: any,
	t: (translationCode: string, params?: any) => string,
	defaultNewValue?: any
};

export default function CRUDDetail(props: Props) {
	const params = useParams<Record<string, string>>();
	const { id } = params;
	const { path } = useRouteMatch();
	const isNew = path.endsWith('/new');
	const isEditing = isNew || path.endsWith('/edit');
	const { data, mutate } = useDetailItem(isNew ? null : props.config.location, params);
	const history = useHistory();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [hasChanges, setHasChanges] = useState(false);
	const [saveResult, setSaveResult] = useState<any>(undefined);
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const [newData, setNewData] = useState<any>();
	const { t } = props;
	const { defaultNewValue } = props.config;

	useEffect(() => {
		if (isNew && !newData) setNewData({ ...defaultNewValue });
	}, [isNew, newData, defaultNewValue])

	useEffect(() => {
		if (isNew) return;
		if ((!hasChanges && !newData) || !isEditing) setNewData(data);
	}, [data, hasChanges, newData, isEditing, isNew]);

	const save = async () => {
		if (!newData) return null;

		setSaveResult(undefined);
		setIsSubmitting(true);
		const key = isNew ? 'new' : id;
		const result = await saveItem(key, newData);
		setSaveResult(result);
		setIsSubmitting(false);

		if (result?.success) {
			setTimeout(() => {
				mutate();
				history.push(props.path);
			}, 1000);
		}
	};

	const confirmDelete = async () => {
		if (!id) return null;

		setIsSubmitting(true);
		const result = await deleteItem(id);
		setIsSubmitting(false);

		if (result?.success) {
			mutate();
			history.push(props.path);
		}
	};

	const renderActions = () => (
		<div className='actions'>
			{isEditing &&
				<Button
					color={'green'}
					icon={<FontAwesomeIcon icon={faCheck} />}
					onClick={save}
					showSpinner={isSubmitting}
				>
					{t('crud.button_item_save')}
				</Button>
			}

			{!isEditing &&
				<ButtonLink
					to={`${props.path}/${id}/edit`}
					className='back'
					icon={<FontAwesomeIcon icon={faPencilAlt} />}
				>
					{t('crud.button_item_edit')}
				</ButtonLink>
			}

			{!isNew &&
				<Button
					color={'red'}
					icon={<FontAwesomeIcon icon={faTrashAlt} />}
					onClick={() => setShowDeleteConfirmation(true)}
				>
					{t('crud.button_item_delete')}
				</Button>
			}

			{!isNew && isEditing &&
				<Button
					color={'orange'}
					icon={<FontAwesomeIcon icon={faTimes} />}
					onClick={() => history.push(`${props.path}/${id}`)}
				>
					{t('crud.button_item_cancel_edit')}
				</Button>
			}

			{isNew &&
				<ButtonLink
					color={'orange'}
					icon={<FontAwesomeIcon icon={faTimes} />}
					to={props.path}
				>
					{t('crud.button_item_cancel_new')}
				</ButtonLink>
			}
		</div>
	);

	const renderDeleteConfirmation = () => showDeleteConfirmation && (
		<PortalModal className='delete__confirmation' close={() => setShowDeleteConfirmation(false)}>
			<div className='delete_modal'>
				<div className='modal__text'>
					{t('crud.label_delete_confirmation_question')}
				</div>
				<div className='actions'>
					<Button
						color={'red'}
						onClick={confirmDelete}
						showSpinner={isSubmitting}
						disabled={isSubmitting}
						icon={<FontAwesomeIcon icon={faTrashAlt} />}
					>
						{t('crud.button_confirm_delete_item')}
					</Button>
					<Button
						onClick={() => setShowDeleteConfirmation(false)}
						icon={<FontAwesomeIcon icon={faTimes} />}
					>
						{t('crud.button_cancel_delete_item')}
					</Button>
				</div>
			</div>
		</PortalModal>
	);

	const renderSaveResult = () => {
		if (saveResult === undefined) return null;

		if (saveResult instanceof Error) {
			return (
				<div className='save-message error'>
					{t('crud.label_save_error', saveResult.message)}
				</div>
			);
		}

		if (!saveResult?.success) {
			return (
				<div className='save-message error'>
					{t('crud.label_save_error', saveResult.error)}
				</div>
			);
		}

		if (saveResult?.success) {
			return (
				<div className='save-message success'>
					{t('crud.label_save_success')}
				</div>
			);
		}
		
		return null;
	};

	const renderTitle = () => (
		<>
			<BackButton
				to={props.path}
				className='back'
			>
				{t(`crud.button_to_list${hasChanges && isEditing ? '_cancel' : ''}`)}
			</BackButton>
			<CharlesTitle alt>{t(`crud.title_detail_${props.mode}`)}</CharlesTitle>
		</>
	);

	const renderForm = () => (
		<DynamicForm
			fields={getFields(props.config.fields, props.fields)}
			sections={props.config.sections ?? false}
			values={newData ?? {}}
			onChange={(d: any) => {
				setNewData(d);
				setHasChanges(true);
			}}
			disabled={!isEditing}
		/>
	);

	const renderHints = () => (
		<div className='hints'>
			{renderSaveResult()}
		</div>
	);

	const renderModals = () => (
		<>
			{renderDeleteConfirmation()}
		</>
	);

	if (props.children) {
		const components = [
			renderTitle,
			renderForm,
			renderHints,
			renderActions,
			renderModals
		];

		return (
			<>
				{props.children(components)}
			</>
		);
	}

	return (
		<div className='CRUDDetail'>
			{renderTitle()}
			{renderForm()}
			{renderHints()}
			{renderActions()}
			{renderModals()}
		</div>
	);
}
