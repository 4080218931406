/**
 * Returns the items that contain the query
 * @param {string} query The search query
 * @param {Array} items The items the filter should be applied on
 * @param {Array} keys The keys on which to apply the filter
 * @param {Boolean} scoreItems Whether the items should be given a score
 * @returns {Array} Array of filtered items
 */
export const filterItemsByQuery = (query = '', items, keys, scoreItems = false, searchOperator = 'AND', ignoredChars = ['\'']) => {
	if (query === '') return items;

	// Trim whitespaces around and in query
	query = query.toLowerCase().trim();
	query = query.replace(/ +/g, ' ');

	const filteredItems = items.map(item => {
		return filterItemByQuery(item, keys, query, scoreItems, searchOperator, ignoredChars);
	});

	return filteredItems.filter(item => item !== null);
};

const filterItemByQuery = (item, keys, query, scoreItems, searchOperator, ignoredChars) => {
	const queries = query.split(' ');

	let score = 0;

	// Check how many queries have a match
	// All queries should match to return true
	const queriesMap = queries.map(q => {

		// If at least one key has a match,
		// consider it a match for the query
		const keyMatch = keys.map(key => {
			const keyParts = key.name.split('.');
			let value = item;
			keyParts.forEach(k => {
				value = value[k];
			});

			if (!value) return false;
			if (typeof key.getPropertyString === 'function') value = key.getPropertyString(item);
			value = value.toString().toLowerCase();
			const match = stringContainsQuery(value, q, ignoredChars);

			let scoreIncrease = 1;
			if (value === q) scoreIncrease = 3;
			if (key.multiplier) scoreIncrease = scoreIncrease * key.multiplier;

			score = match ? score + scoreIncrease : score;
			return match;
		});

		const matchesAtLeastOneKey = keyMatch.indexOf(true) !== -1;
		return matchesAtLeastOneKey;
	});

	let containsAllQueries = false;
	if (searchOperator === 'OR') {
		containsAllQueries = queriesMap.indexOf(true) !== -1;
	} else if (searchOperator === 'AND') {
		containsAllQueries = queriesMap.indexOf(false) === -1;
	}

	if (containsAllQueries) {
		if (scoreItems) item.score = score;
		return item;
	}

	return null;
};

const stringContainsQuery = (string, query, ignoredChars) => {
	query = query.replace(/[\\.+*?^$[\](){}/'#:!=|]/gi, '\\$&');

	ignoredChars.forEach(char => {
		query = query.replace(char, `${char}?`);
	});

	const regex = new RegExp(`(${query})`, 'gi');
	const contains = string.match(regex);
	return !!contains;
};
