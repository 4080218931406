import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from './../../../store';
import * as API from './../../../api';
import { Accounts, Account } from './accountsInterfaces';
import { filterItemsByQuery } from './../../../libraries/filter';
import { toast } from 'react-toastify';
import { Supplier } from './../../suppliers/suppliersInterfaces';
import { t } from './../../../libraries/i18n';

interface AccountsState {
	isFetching: boolean;
	error?: string;
	accountsById: Accounts;
	query: string;
	isUpdating: Array<string>;
	didInvalidate: boolean;
}

const initialState: AccountsState = {
	isFetching: false,
	accountsById: {},
	query: '',
	isUpdating: [],
	didInvalidate: false
}

export const accountsSlice = createSlice({
	name: 'accounts',
	initialState,
	reducers: {
		getAccountsStart: (state: AccountsState): void => { state.isFetching = true },
		getAccountsSuccess: (state: AccountsState, { payload }: PayloadAction<Accounts>): void => {
			state.accountsById = payload;
			state.isFetching = false;
			state.didInvalidate = false;
		},
		getAccountsFailed: (state: AccountsState, { payload }: PayloadAction<string>): void => {
			state.error = payload;
			state.isFetching = false;
		},
		setQuery: (state: AccountsState, { payload }: PayloadAction<string>): void => {
			state.query = payload;
		},
		updateAccountStart: (state: AccountsState, { payload }: PayloadAction<Account>): void => {
			state.isUpdating.push(payload.id);
		},
		updateAccountSuccess: (state: AccountsState, { payload }: PayloadAction<Account>): void => {
			state.isUpdating = state.isUpdating.filter(a => a !== payload.id);
		},
		setAccount: (state: AccountsState, { payload }: PayloadAction<Account>): void => {
			state.accountsById[payload.id] = payload;
		},
		deleteAccount: (state: AccountsState, { payload }: PayloadAction<Account>): void => {
			delete state.accountsById[payload.id];
		},
		addAccount: (state: AccountsState, { payload }: PayloadAction<Account>): void => {
			state.accountsById[payload.id] = payload;
		},
		invalidateAccounts: (state: AccountsState): void => {
			state.didInvalidate = true;
		}
	}
});

export const {
	getAccountsStart,
	getAccountsSuccess,
	getAccountsFailed,
	setQuery,
	updateAccountStart,
	updateAccountSuccess,
	setAccount,
	deleteAccount,
	addAccount,
	invalidateAccounts
} = accountsSlice.actions;

export const fetchAccountsIfNeeded = (): AppThunk => async (dispatch, getState) => {
	const state = getState();
	if (selectIsFetching(state)) return;
	if (selectDidAccountsInvalidate(state)) return dispatch(fetchAccounts());
	if (selectAccountsById(state) !== initialState.accountsById) return;
	dispatch(fetchAccounts());
};

const fetchAccounts = (): AppThunk => async dispatch => {
	try {
		dispatch(getAccountsStart());
		const accountsById = await API.getAccounts();
		dispatch(getAccountsSuccess(accountsById));
	} catch (error) {
		dispatch(getAccountsFailed(error.toString()));
	}
};

export const updateAccount = (account: Account): AppThunk => async dispatch => {
	dispatch(updateAccountStart(account));
	
	try {
		const res = await API.updateAccount(account);
		if (!res.success) throw new Error(res);
		dispatch(updateAccountSuccess(account));
		dispatch(setAccount(account));
		toast.success(t('accounts.message_edit_success'));
	} catch (error) {
		toast.error(t('accounts.message_edit_fail'), { autoClose: false });
	}
};

export const removeAccount = (account: Account): AppThunk => async dispatch => {
	dispatch(updateAccountStart(account));

	try {
		const res = await API.deleteAccount(account);
		if (!res.success) throw new Error(res);
		dispatch(updateAccountSuccess(account));
		dispatch(deleteAccount(account));
		toast.success(t('accounts.message_delete_success'));
	} catch (error) {
		toast.error(t('accounts.message_delete_fail'), { autoClose: false });
	}
};

export const createAccount = (account: Account): AppThunk => async dispatch => {
	dispatch(updateAccountStart(account));

	try {
		const res = await API.createAccount(account);
		if (!res.success) throw new Error(res);
		
		toast.success(t('accounts.message_create_success'));
		dispatch(fetchAccounts());
	} catch (error) {
		toast.error(t('accounts.message_create_fail'), { autoClose: false });
	}
};

export const selectIsAccountUpdating = (state: RootState, id: string) => state.accounts.isUpdating.includes(id);
export const selectQuery = (state: RootState) => state.accounts.query;
export const selectIsFetching = (state: RootState) => state.accounts.isFetching;
export const selectAccountsById = (state: RootState) => state.accounts.accountsById;
export const selectAccountById = (state: RootState, id: string) => state.accounts.accountsById[id];
export const selectAccounts = (state: RootState) => Object.values(selectAccountsById(state));
export const selectDidAccountsInvalidate = (state: RootState) => state.accounts.didInvalidate;
export const selectVisibleAccounts = (state: RootState) => {
	const accounts = Object.values(selectAccountsById(state));
	const filterKeys = [
		{ name: 'email' },
		{ name: 'name' },
		{
			name: 'suppliers',
			getPropertyString: (account: Account) => {				
				const suppliersStrings = account.suppliers.map((supplier: Supplier) => {
					return `${supplier.code} ${supplier.name}`;
				}).filter(s => s);

				return suppliersStrings.toString();
			}
		}
	];
	const filterResult = filterItemsByQuery(
		selectQuery(state),
		accounts,
		filterKeys
	);
	return filterResult;
};

export default accountsSlice.reducer;
