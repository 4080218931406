import React from 'react';
import './BannersInput.scss';
import { DynamicForm, Button } from '@abm-international/react-components';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';
import { ICampaignBanner, CampaignDocument, CampaignBanner } from '../../../interfaces/marketing/campaigns';
import { t } from '../../../libraries/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const getNewEmptyItem = () => ({
	id: uuidv4(),
	image: {
		multilingual: false,
		nl: ''
	},
	action: {
		type: 'none'
	}
});

export default function BannersInput(props: any) {
	const handleChange = (index: number, newValue: any) => props.onChange([
		...props.value.slice(0, index),
		newValue,
		...props.value.slice(index + 1)
	]);

	const deleteItem = (index: number) => props.onChange([
		...props.value.slice(0, index),
		...props.value.slice(index + 1)
	]);

	const renderItem = (banner: ICampaignBanner, index: number) => {
		const getFields = () => {
			let fields: any = [
				{
					title: 'Is bestand meertalig?',
					name: ['image', 'multilingual'],
					type: 'checkbox'
				},
				{
					title: 'Bestand NL',
					name: ['image', 'nl'],
					type: 'file',
					options: {
						...props.fileInputProps,
						accept: '.jpg, .jpeg, .png, .gif',
						preview: (value: string) => <img src={value} className='file-input--preview' alt='Uw geupload bestand' />
					}
				}
			];

			if (!banner.image.multilingual) {
				fields = [
					...fields,
					{
						title: 'Bestand FR',
						name: ['image', 'fr'],
						type: 'file',
						options: {
							...props.fileInputProps,
							accept: '.jpg, .jpeg, .png, .gif',
							preview: (value: string) => <img src={value} className='file-input--preview' alt='Uw geupload bestand' />
						}
					},
				];
			}

			fields = [
				...fields,
				{
					title: 'Actie bij klik op banner',
					name: ['action', 'type'],
					type: 'select',
					options: {
						choices: [
							{
								title: 'Geen',
								value: 'none'
							},
							{
								title: 'Document',
								value: 'document'
							},
							{
								title: 'Filter',
								value: 'filter'
							}
						]
					}
				}
			];

			if (banner.action.type === 'document') {
				fields = [
					...fields,
					{
						title: 'Document',
						name: ['action', 'doc'],
						type: 'select',
						options: {
							choices: [
								{
									title: 'Selecteer een document',
									value: ''
								},
								...props.documents?.map((doc: CampaignDocument) => ({
									title: doc.description.nl || t('campaigns.label_document_without_name'),
									value: doc.id
								})) ?? [],
							]
						}
					}
				];
			}

			fields = [
				...fields,
				{
					title: 'Gewicht',
					name: 'weight',
					type: 'number'
				},
				{
					title: 'Heeft dit document afwijkende start- en/of einddatums',
					name: 'hasDeviatingDates',
					type: 'checkbox'
				}
			];

			if (banner.hasDeviatingDates) {
				fields = [
					...fields,
					{
						title: 'Start- en einddatum',
						name: 'startAndEnd',
						type: 'datetimerange',
						getValue: (values: CampaignBanner) => [values.start, values.end],
						setValue: (values: CampaignBanner, newValue: any) => ({ ...values, start: newValue[0], end: newValue[1] }),
						options: {
							translations: props.translations,
							highlightedDates: props.highlightedDates
						}
					}
				];
			}

			return fields;
		};

		return (
			<div key={banner.id} className='item'>
				<DynamicForm
					fields={getFields()}
					values={banner}
					onChange={(newValue: ICampaignBanner) => handleChange(index, newValue)}
					formId={banner.id}
					nested
					disabled={props.disabled}
				/>
				<Button
					onClick={() => deleteItem(index)}
					icon={<FontAwesomeIcon icon={faTrashAlt} />}
					color={'red'}
					className={'delete'}
					disabled={props.disabled}
				>
					Verwijderen
				</Button>
			</div>
		);
	}

	const renderItems = (banners: Array<ICampaignBanner>) => (
		<div className='items'>
			{banners?.map(renderItem)}
		</div>
	);

	const renderActions = () => (
		<div className='actions'>
			<Button
				onClick={() => props.onChange([...(props?.value ?? []), { ...getNewEmptyItem() }])}
				color={'green'}
				icon={<FontAwesomeIcon icon={faPlus} />}
				disabled={props.disabled}
			>
				Banner toevoegen
			</Button>
		</div>
	);

	return (
		<div className='BannersInput'>
			{renderItems(props.value)}
			{renderActions()}
		</div>
	);
}
