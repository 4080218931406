import React, { useEffect, SyntheticEvent } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import './accounts.scss';
import { CharlesTitle, List, Spinner, ButtonLink } from '@abm-international/react-components';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchAccountsIfNeeded,
	selectIsFetching,
	selectVisibleAccounts,
	setQuery,
	selectQuery,
	selectAccounts
} from './accountsSlice';
import { Account, AccountType } from './accountsInterfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faPencilAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { fetchSuppliersIfNeeded } from './../../suppliers/suppliersSlice';
import SupplierBadge from './../../../components/SupplierBadge/SupplierBadge';
import Search from './../../../components/Search/Search';
import { t } from './../../../libraries/i18n';

function Accounts() {
	const dispatch = useDispatch();
	const isFetching = useSelector(selectIsFetching);
	const visibleAccounts = useSelector(selectVisibleAccounts);
	const accounts = useSelector(selectAccounts);
	const query = useSelector(selectQuery);
	const history = useHistory();
	const match = useRouteMatch();

	useEffect(() => {
		dispatch(fetchAccountsIfNeeded());
		dispatch(fetchSuppliersIfNeeded());
	}, [dispatch]);

	const accountAction = (e: SyntheticEvent, account: Account) => {
		e.stopPropagation();

		if (!e.defaultPrevented) {
			const masterAccountId = account.type === AccountType.Master
				? account.id
				: accounts.find((a: Account) => a.account === account.account && a.type === AccountType.Master)?.id;
			history.push(`${match.url}/${masterAccountId}`);
		}
	};

	const renderEmail = (account: Account) => (
		<div className={account.isBlocked ? 'blocked' : ''}>
			{account.isBlocked && <FontAwesomeIcon icon={faBan} />}
			{account.email}
		</div>
	);

	const renderName = (account: Account) => (
		<div className={account.isBlocked ? 'blocked' : ''}>
			{account.name}
		</div>
	);

	const renderSuppliers = (account: Account) => account?.suppliers?.length <= 0 ? null : (
		<div className={`suppliers  ${account.isBlocked ? 'blocked' : ''}`}>
			{account.suppliers.map(supplier => <SupplierBadge key={supplier.id} supplier={supplier} />)}
		</div>
	);

	const renderAccountButton = (account: Account) => account.type === AccountType.Sub ? null : (
		<ButtonLink
			to={`${match.url}/${account.id}/edit`}
			icon={<FontAwesomeIcon icon={faPencilAlt} />}
		>
			{t('accounts.button_edit')}
		</ButtonLink>
	);

	const sortedAccounts = visibleAccounts.sort((a: Account, b: Account) => {
		if (a.account !== b.account) return b.account.localeCompare(a.account);
		if (a.type === AccountType.Master) return -1;
		if (b.type === AccountType.Master) return 1;

		return b.id.localeCompare(a.id);
	});

	return (
		<div className="Accounts">
			<CharlesTitle>{t('accounts.title_accounts_overview')}</CharlesTitle>

			<Search value={query} placeholder={t('accounts.placeholder_search')} onChange={(e) => dispatch(setQuery(e.target.value))} />

			<ButtonLink
				to={`${match.path}/new`} className={'new-account'}
				icon={<FontAwesomeIcon icon={faUserPlus} />}
			>
				{t('accounts.button_new_account')}
			</ButtonLink>

			{isFetching && <Spinner />}

			{visibleAccounts.length > 0 && !isFetching && (
				<List
					gridTemplateColumns='1fr 1fr 10rem 1fr 15rem'
					header={[
						{
							key: 'email',
							title: t('accounts.title_email'),
							render: renderEmail
						},
						{
							key: 'name',
							title: t('accounts.title_name'),
							render: renderName
						},
						{
							key: 'type',
							title: t('accounts.title_type'),
							render: ({ type }: Account) => type
						},
						{
							key: 'supplier',
							title: t('accounts.title_suppliers'),
							render: renderSuppliers
						},
						{
							key: 'button',
							title: '',
							render: renderAccountButton
						}
					]}
					content={sortedAccounts}
					action={accountAction}
				/>
			)}
		</div>
	);
}

export default Accounts;
